import cn from "classnames";
import TestimonialsCarousel from "./TestimonialsCarousel";
import { useLanguage } from "../../../language-content";

import styles from "./Testimonial.module.css";

const Testimonials = ({ type }) => {
  const { languageData } = useLanguage();

  const { heading, subheading } =
    type === "teacher"
      ? languageData["teach_with_us"]["testimonials"]
      : languageData["student_testimonials"];

  return (
    <section className={cn(styles["testimonial"])}>
      <div className={cn(styles["testimonial__text"])}>
        <h1 className={cn(styles["testimonial__heading"])}>{heading}</h1>
        { type === "teacher" && <p className="subheading">{subheading}</p> }
      </div>
      <TestimonialsCarousel type={type} />
    </section>
  );
};

export default Testimonials;
