import React from 'react'
import BestTeachers from './BestTeachers'
import Testimonials from './Testimonials'
// import TestimonialsCarousel from './TestimonialsCarousel'

const MetricsAndTestimonials = () => {
    return (
      <>
        <BestTeachers />
        <Testimonials />
        {/* <TestimonialsCarousel /> */}
      </>
    );
}

export default MetricsAndTestimonials
