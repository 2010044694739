import { useLanguage } from "../language-content";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./Footer.module.css";
//Testing
const Footer = () => {
  const { languageData } = useLanguage();

  const { links, copyright } = languageData["footer"];
  return (
    <footer className={cn(styles["footer"])}>
      <div>
        {links.map((link, index) => {
          return (
            <span key={link.text}>  
            <Link to={link.path} className={cn(styles["footer__links"])}>
              {link.text}
            </Link>
            {index !== links.length - 1 && <span className="m-03">|</span>}
            </span>
          )
        })}
      </div>
      <p className={cn(styles["footer__links"])}>{copyright}</p>
    </footer>
  );
};

export default Footer;
