/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlueButton, IconButton, IconWithTextButton } from "../utils/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faBars,
  faPhoneAlt,
} from "../utils/icons";
import cn from "classnames";
import { useLanguage } from "../language-content";
import { CloudinaryImageHelper } from "../utils/image-helper";
import { useLocation } from "react-router-dom";
import { gaButtonClickHandler } from "../utils/google-analytics";

import styles from "./Navbar.module.css";

const Navbar = ({ handleMenuToggle }) => {
  const [courseSubmenuDisplay, setCourseSubmenuDisplay] = useState(false);
  const [moreSubmenuDisplay, setMoreSubmenuDisplay] = useState(false);
  const [phoneSubmenuDisplay, setPhoneSubmenuDisplay] = useState(false);
  const [k12Submenu, setK12Submenu] = useState(false);
  const [adLearnerSubmenu, setAdLearnerSubmenu] = useState(false);
  const [navSticky, setNavSticky] = useState(false);

  const { languageData, currentLanguage } = useLanguage();
  const navData = languageData.nav;

  const { pathname } = useLocation();

  function handleCourseSubmenu() {
    setCourseSubmenuDisplay(!courseSubmenuDisplay);
  }

  function handleMoreSubmenu() {
    setMoreSubmenuDisplay(!moreSubmenuDisplay);
  }

  function handleK12Submenu() {
    setK12Submenu(!k12Submenu);
  }

  function handleAdLearnerSubmenu() {
    setAdLearnerSubmenu(!adLearnerSubmenu);
  }

  function handlePhoneSubmenu() {
    setPhoneSubmenuDisplay(!phoneSubmenuDisplay);
  }

  function handleSelectChange(e) {
    // handleLanguageSelection(e.target.value)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    setNavSticky(window.pageYOffset > 0);
  }

  function handleReset() {
    setAdLearnerSubmenu(false);
    setCourseSubmenuDisplay(false);
    setK12Submenu(false);
    setPhoneSubmenuDisplay(false);
  }

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, [pathname]);

  return (
    <nav
      className={cn(styles.navbar, navSticky && "navbar--active")}
    >
      <Link to="/">
        <CloudinaryImageHelper
          publicId="logo_learnhat"
          alt="learnhat_logo"
          width="180"
          height="50"
        />
      </Link>
      <div className={cn(styles["navbar__links"])}>
        <IconWithTextButton
          onClick={() => setCourseSubmenuDisplay(true)}
          onHover={handleCourseSubmenu}
          className={"nav-icon-btn"}
        >
          {navData["links"][0].text}
          <FontAwesomeIcon icon={faChevronDown} className={"ml-1"} />
          <CoursesSubmenu
            courseSubmenuDisplay={courseSubmenuDisplay}
            handleCourseSubmenu={handleCourseSubmenu}
            handleK12Submenu={handleK12Submenu}
            k12Submenu={k12Submenu}
            adLearnerSubmenu={adLearnerSubmenu}
            handleAdLearnerSubmenu={handleAdLearnerSubmenu}
            subMenuData={navData["links"][0].submenu}
            setK12Submenu={setK12Submenu}
            setAdLearnerSubmenu={setAdLearnerSubmenu}
            handleReset={handleReset}
          />
        </IconWithTextButton>
        {navData.links.map((navItem) => {
          return navItem.path ? (
            <Link
            onClick={() => gaButtonClickHandler("Button", navItem.text,window.location.pathname)}
              key={navItem.text}
              to={navItem.path}
              className={cn(styles["navbar__linkitems"])}
            >
              {navItem.text}
            </Link>
          ) : (
            navItem.type === "newWindow" && (
              <a
              onClick={() => gaButtonClickHandler("Button", navItem.text,window.location.pathname)}
                key={navItem.text}
                href={navItem.to}
                target="_blank"
                rel="noreferrer"
                className={cn(styles["navbar__linkitems"])}
              >
                {navItem.text}
              </a>
            )
          );
        })}
        {/* <IconWithTextButton
          onClick={() => setMoreSubmenuDisplay(true)}
          onHover={handleMoreSubmenu}
          className={"nav-icon-btn"}
        >
          {navData["links"][3].text}
          <FontAwesomeIcon icon={faChevronDown} className={"ml-1"} />
          <MoreSubmenu
            moreSubmenuDisplay={moreSubmenuDisplay}
            handleMoreSubmenu={handleMoreSubmenu}
            subMenuData={navData["links"][3].submenu}
          />
        </IconWithTextButton> */}
        <select onChange={handleSelectChange}>
          {["ENGLISH", "HINDI"].map((lang) => {
            return (
              <option
                key={lang}
                value={lang}
                selected={currentLanguage === lang}
              >
                {lang}
              </option>
            );
          })}
        </select>
        <Link to="/#hero">
          <BlueButton
          onClick={() => gaButtonClickHandler("Button", "Free Trial Class",window.location.pathname )}
          >{navData["nav_cta_1"]}</BlueButton>
        </Link>
        <a href="https://lms.learnhat.com/" target="_blank" rel="noreferrer">
          <BlueButton className={"btn-transparent--blue"}>
            {navData["nav_cta_2"]}
          </BlueButton>
        </a>
        <IconButton
          onClick={() => setPhoneSubmenuDisplay(true)}
          onHover={handlePhoneSubmenu}
        >
          <FontAwesomeIcon icon={faPhoneAlt} className="phone-icon" />
          <PhoneSubmenu
            phoneSubmenuDisplay={phoneSubmenuDisplay}
            handlePhoneSubmenu={handlePhoneSubmenu}
          />
        </IconButton>
      </div>
      <IconButton className={"menu-icon"} onClick={handleMenuToggle}>
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
    </nav>
  );
};

const PhoneSubmenu = ({ phoneSubmenuDisplay, handlePhoneSubmenu }) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        styles["navbar__submenu--one-item"],
        phoneSubmenuDisplay && styles["active"]
      )}
      onMouseLeave={handlePhoneSubmenu}
    >
      <a
        href="tel:1800 833 5077"
        className={cn(styles["navbar__submenu-item"])}
      >
        1800 833 5077
      </a>
    </div>
  );
};

const CoursesSubmenu = ({
  courseSubmenuDisplay,
  subMenuData,
  k12Submenu,
  handleK12Submenu,
  adLearnerSubmenu,
  handleAdLearnerSubmenu,
  setK12Submenu,
  setAdLearnerSubmenu,
  handleReset,
}) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        courseSubmenuDisplay && styles.active
      )}
      onMouseLeave={handleReset}
    >
      <IconWithTextButton
        width100={true}
        onClick={() => setK12Submenu(true)}
        onHover={handleK12Submenu}
      >
        {subMenuData[0].text}
        <FontAwesomeIcon icon={faChevronRight} className={"ml-1"} />
        <K12Submenu
          k12Submenu={k12Submenu}
          handleK12Submenu={handleK12Submenu}
          subMenuData={subMenuData[0].submenu}
          handleReset={handleReset}
        />
      </IconWithTextButton>
      <IconWithTextButton
        width100={true}
        onClick={() => setAdLearnerSubmenu(true)}
        onHover={handleAdLearnerSubmenu}
      >
        {subMenuData[1].text}
        <FontAwesomeIcon icon={faChevronRight} className={"ml-1"} />
        <AdLearnerSubmenu
          adLearnerSubmenu={adLearnerSubmenu}
          handleAdLearnerSubmenu={handleAdLearnerSubmenu}
          subMenuData={subMenuData[1].submenu}
        />
      </IconWithTextButton>
    </div>
  );
};

const K12Submenu = ({ k12Submenu, handleK12Submenu, subMenuData }) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        styles["navbar__submenu--lvl2"],
        k12Submenu && styles.active
      )}
      onMouseLeave={handleK12Submenu}
    >
      {subMenuData.map((subMenuItem) => {
        return (
          <Link
            key={subMenuItem.text}
            to={subMenuItem.path}
            className={cn(styles["navbar__submenu-item"])}
          >
            {subMenuItem.text}
          </Link>
        );
      })}
    </div>
  );
};

const AdLearnerSubmenu = ({
  adLearnerSubmenu,
  handleAdLearnerSubmenu,
  subMenuData,
}) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        styles["navbar__submenu--lvl2"],
        styles["navbar__submenu--lvl2--3item"],
        adLearnerSubmenu && styles.active
      )}
      onMouseLeave={handleAdLearnerSubmenu}
    >
      {subMenuData.map((subMenuItem) => {
        return (
          <Link
            key={subMenuItem.text}
            to={subMenuItem.path}
            className={cn(styles["navbar__submenu-item"])}
          >
            {subMenuItem.text}
          </Link>
        );
      })}
    </div>
  );
};

const MoreSubmenu = ({
  moreSubmenuDisplay,
  handleMoreSubmenu,
  subMenuData,
}) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        styles["navbar__submenu--three-item"],
        moreSubmenuDisplay && styles.active
      )}
      onMouseLeave={handleMoreSubmenu}
    >
      {subMenuData.map((subMenuItem) => {
        return (
          <Link
            key={subMenuItem.text}
            to={subMenuItem.path}
            className={cn(styles["navbar__submenu-item"])}
          >
            {subMenuItem.text}
          </Link>
        );
      })}
    </div>
  );
};

export default Navbar;
