import cn from "classnames";
import styles from "./Buttons.module.css";

const BlueButton = ({ children, className, onClick }) => {
  return (
    <button
      className={cn(styles.btn, styles["btn--blue"], styles[className])}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const PurpleButton = ({ btnType, children, className, id, type, onClick, disabled }) => {
  return (
    <button
      type={type}
      id={id}
      className={cn(
        styles.btn,
        styles["btn-purple"],
        styles[btnType],
        styles[className],
        disabled && styles["btn--gray"]
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const YellowButton = ({
  btnType,
  children,
  className,
  id,
  type,
  onClick,
  disabled,
}) => {
  return (
    <button
      type={type}
      id={id}
      className={cn(
        styles.btn,
        styles["btn-yellow"],
        styles[btnType],
        className,
        disabled && styles["btn--gray"]
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const IconButton = ({ children, className, onClick, onHover }) => {
  return (
    <button
      className={cn(styles.btnicon, className)}
      onClick={onClick}
      onMouseOver={onHover}
      onMouseOut={onHover}
    >
      {children}
    </button>
  );
};

const IconWithTextButton = ({ children, onClick, onHover, className, width100 }) => {
  return (
    <button
      onClick={onClick}
      onMouseOver={onHover}
      onMouseOut={onHover}
      className={cn(styles["btn-iconwithtext"], className, width100 && styles["btn-hover-purple"])}
      style={width100 && { width: "100%" }}
    >
      {children}
    </button>
  );
};

export { BlueButton, PurpleButton, IconButton, IconWithTextButton, YellowButton };
