import cn from "classnames";
import styles from "./Input.module.css";

const InputText = ({
  placeholder,
  onChange,
  required,
  className,
  id,
  name,
  autoComplete,
  maxLength,
  value,
  type,
  onBlur,
  height,
  disabled
}) => {
  return (
    <input
      required={required}
      className={cn(styles.input, className)}
      type={type ? type : "text"}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      id={id}
      name={name}
      maxLength={maxLength}
      autoComplete="off"
      onBlur={onBlur}
      disabled={disabled}
      style={{ height: `${height}px` }}
    />
  );
};

const InputSelect = ({ children, onChange, id, name, className, disabled, onBlur }) => {
  return (
    <select
      onChange={onChange}
      className={cn(styles.input, styles["input-select"], className)}
      id={id}
      name={name}
      disabled={disabled}
      onBlur={onBlur}
    >
      {children}
    </select>
  );
};

const InputMobileNumber = ({
  placeholder,
  onChange,
  required,
  className,
  id,
  name,
  maxLength,
  value,
  onBlur,
  height,
  width
}) => {
  return (
    <div className={cn(styles.mobilenumber)} style={{ width: `${width}` }}>
      <div
        className={cn(styles["input-countrycode"], width === "100%" && "mt-02")}
      >
        <p>+91</p>
      </div>
      <input
        required={required}
        className={cn(styles.input, styles.inputmobilenumber, className)}
        type="number"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        id={id}
        name={name}
        maxLength={maxLength}
        autoComplete="off"
        onBlur={onBlur}
        style={{ height: `${height}px` }}
      />
    </div>
  );
};

export { InputText, InputSelect, InputMobileNumber };
