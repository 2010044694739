/* eslint-disable */
import { useState, useEffect } from "react";
import { convertToEncryption } from "../../utils/crypto";
import axios from "axios";
import cn from "classnames";
import { useCourseDetail } from "../courses";
import { useNavigate } from "react-router-dom";

import styles from "./PaymentDetails.module.css";
import { InputText } from "../../utils/input";
import { BlueButton, PurpleButton } from "../../utils/buttons";

import { validateName, validateEmail } from "../../utils/validation"

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const PaymentDetails = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [emiPlans, setEmiPlans] = useState([]);
  const [payingWithEmi, setPayingWithEmi] = useState(false);
  const [paymentGatewayType, setPaymentGatewayType] = useState("");
  const [nameValidationMsg, setNameValidationMsg] = useState("");
  const [emailValidationMsg, setEmailValidationMsg] = useState("");

  const { courseDetailState, courseDetailDispatch } = useCourseDetail();

  const {
    selectedGrade,
    selectedSubject,
    selectedBoard,
    selectedBatch,
    selectedPlan,
    phoneNumber,
    otpVerifiedToken,
    pe,
    tp,
  } = courseDetailState;

  const navigate = useNavigate();

  useEffect(() => {
    if (
      selectedGrade.length === 0 ||
      selectedSubject.length === 0 ||
      selectedBoard.length === 0
    ) {
      navigate("/coursesk12");
    }
  }, []);

  const {
    subject_plan_id,
    subject_price,
    subject_price_duration,
    subject_price_duration_type_value,
  } = selectedPlan;

  const passingObjectForPaymentDetails = {
    select_class: selectedGrade[0],
    booking_subje: selectedSubject[0],
    booking_subject_price: subject_plan_id,
    plan_price: subject_price,
    subject_price_duration,
    subject_price_duration_type_value,
    country_code: 3,
    self_or_tutor_study: 3,
    slot_id: selectedBatch,
    student_reference: "Learnhat",
    board_id: selectedBoard[0],
    booker_name: name,
    booker_email: email,
    booker_phone: phoneNumber,
    coupon_code: couponCode,
    booking_otp: "",
    emi_option_avail: payingWithEmi,
    is_term_condition_accepted: 1,
    payment_mode: "razorpay",
    reference_mail_id: "",
    pe,
    tp,
  };

  function checkName(){
    setNameValidationMsg(validateName(name))
  }

  function checkEmail() {
    setEmailValidationMsg(validateEmail(email));
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function handlePaymentClick(){
    checkEmail()
    checkName()
    if(name !== "" && email !== "" && nameValidationMsg === "" && emailValidationMsg === ""){
      displayRazorpay()
    }
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let encryptedTextToSend = convertToEncryption(
      passingObjectForPaymentDetails
    );
    let bodyFormData = new FormData();
    bodyFormData.append("param1", encryptedTextToSend);

    const response = await axios({
      method: "post",
      url: `https://api.learnhat.com/Account/onAddBookingforapi`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (!response) {
      alert("Server error. Are you online?");
      return;
    }

    const {
      razorpay,
      booker_email,
      booking_id,
      paytm_code,
      mtx,
      payment_token,
      payment,
      error,
    } = response.data;

    if (error) {
      alert(error);
    }

    if(!error){
      const options = {
        key: razorpay.key,
        amount: "100",
        currency: "INR",
        name: "LearnHat",
        description: "Course Purchase",
        order_id: razorpay.order_id,
        image: "",
        handler: async function (response) {
          const data = {
            pid: response.razorpay_payment_id,
            oid: razorpay.order_id,
            sig: response.razorpay_signature,
            booker_email,
            booking_id,
            mtx,
          };

          let encryptedTextToSend = convertToEncryption(data);
          let bodyFormData = new FormData();
          bodyFormData.append("param1", encryptedTextToSend);

          const result = await axios({
            method: "post",
            url: `https://api.learnhat.com/Account/validaterajPayment`,
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (result.data.success === "1") {
            alert(payment);
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: phoneNumber,
        },
        notes: {
          address: "",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  }

  useEffect(() => {
    (async function () {
      try {
        const passingObject = {
          select_class: selectedGrade[0] || 7,
          booking_subject: selectedSubject[0] || 87,
          plan_price: subject_price || 3600,
          subject_price_duration: 3,
          subject_price_duration_type_value: "Months",
        };

        var encryptedTextToSend = convertToEncryption(passingObject);
        var bodyFormData = new FormData();
        bodyFormData.append("param1", encryptedTextToSend);

        const { status, data } = await axios({
          method: "post",
          url: `${BACKEND_BASE_URL}/onGetPlanAmountDividation`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (status === 200 && data.success === "1") {
          setEmiPlans(() => data.emi_break_up);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className={cn(styles["paymentdetails"])}>
      <div className={cn(styles["paymentdetails__content"])}>
        <div className={cn(styles["paymentdetails__left"])}>
          <h1 className={cn(styles["paymentdetails__subjectplaninfo"])}>
            Payment For <br />
            <span className={cn(styles["subject-name"])}>
              Class {selectedGrade[1]} - {selectedSubject[1]} -{" "}
              {selectedBoard[1]}
            </span>
            <br />
            {subject_price_duration === "3" &&
              subject_price_duration_type_value === "Months" && (
                <span className={cn(styles["plan-type"])}>
                  (Quarterly Plan)
                </span>
              )}
            {subject_price_duration === "6" &&
              subject_price_duration_type_value === "Months" && (
                <span className={cn(styles["plan-type"])}>
                  (Half-Yearly Plan)
                </span>
              )}
            {subject_price_duration === "1" &&
              subject_price_duration_type_value === "Year" && (
                <span className={cn(styles["plan-type"])}>(Annual Plan)</span>
              )}
            {subject_price_duration === "2" &&
              subject_price_duration_type_value === "Years" && (
                <span className={cn(styles["plan-type"])}>(2 years Plan)</span>
              )}
          </h1>
          <h2 className={cn(styles["plan-price"])}>₹{subject_price}</h2>
          <div className={cn(styles["paymentdetails__userdetails"])}>
            <div>
              <InputText
                value={name}
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
                onBlur={checkName}
              />
              <span className="validation-message">{nameValidationMsg}</span>
            </div>
            <div>
              <InputText
                value={email}
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={checkEmail}
              />
              <span className="validation-message">{emailValidationMsg}</span>
            </div>
          </div>
          <div className={cn(styles["paymentdetails__coupon"])}>
            <InputText placeholder="Coupon Code" />
            <BlueButton>Apply</BlueButton>
          </div>
        </div>
        <div className={cn(styles["paymentdetails__right"])}>
          <h2>Choose a Payment Method</h2>
          <div className={cn(styles["paymentdetails__emi"])}>
            <p>
              You can pay with <span>EMI</span>
            </p>
            <div>
              <input
                type="radio"
                name="emioption"
                id="emiyes"
                onChange={() => setPayingWithEmi(true)}
              />{" "}
              <label htmlFor="emiyes">Yes</label>
              <input
                type="radio"
                name="emioption"
                id="emino"
                onChange={() => setPayingWithEmi(false)}
                className="ml-1"
              />{" "}
              <label htmlFor="emino">No</label>
            </div>
            <div className={cn(styles["paymentdetails__emi-plans"])}>
              {payingWithEmi &&
                emiPlans &&
                emiPlans.length > 0 &&
                emiPlans.map(({ lavel, date, amount }) => {
                  return (
                    <p>
                      {lavel} - {date} - {amount}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className={cn(styles["paymentdetails__gateway"])}>
            <input defaultChecked="true" type="radio" value="razorpay" />
            <p>Razorpay</p>
          </div>
          <PurpleButton onClick={handlePaymentClick}>Pay Now</PurpleButton>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
