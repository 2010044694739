export default function courseDetailReducer(state, { type, payload }) {
  switch (type) {
    case "SET_SELECTED_GRADE":
      return { ...state, selectedGrade: payload.selectedGrade };
    case "SET_SELECTED_SUBJECT":
      return { ...state, selectedSubject: payload.selectedSubject };
    case "SET_SELECTED_BOARD":
      return { ...state, selectedBoard: payload.selectedBoard };
    case "SET_SELECTED_BATCH":
      return { ...state, selectedBatch: payload.selectedBatch };
    case "SET_SELECTED_PLAN":
      return { ...state, selectedPlan: { ...payload.selectedPlan } };
    case "SET_PHONE_NUMBER": 
      return { ...state, phoneNumber: payload.phoneNumber }
    case "SET_OTP": 
      return { ...state, otp: payload.otp }
    case "SET_OTP_TOKEN": 
      return { ...state, otpToken: payload.otpToken }
    case "SET_PE":
      return { ...state, pe: payload.pe }
    case "SET_TP": 
      return { ...state, tp: payload.tp }
    case "SET_OTP_VERIFIED_TOKEN": 
      return { ...state, otpVerifiedToken: payload.otpVerifiedToken }
    case "CLEAR_CHOICES": 
    return {
      ...state,
      selectedGrade: [],
      selectedSubject: [],
      selectedBoard: [],
      selectedBatch: "",
      selectedPlan: "",
      phoneNumber: "",
      otp: "",
      otpToken: "",
      otpVerifiedToken: ""
    }
    default:
      return;
  }
}
