import { Image, Transformation, Placeholder } from "cloudinary-react"

const CloudinaryImageHelper = ({ publicId, width, height, className, alt }) => {
    return (
      <Image
        public-id={`website_images/${publicId}`}
        loading="lazy"
        width={width}
        height={height}
        className={className}
        alt={alt}
      >
        <Transformation fetchFormat="auto" quality="auto:best" crop="scale" />
        <Placeholder type="blur"></Placeholder>
      </Image>
    );
}

export default CloudinaryImageHelper
