import React from 'react'
import cn from "classnames"
import styles from "./Hero.module.css"
import GetAppLink from './GetAppLink'
import HeroSectionText from './HeroSectionText'
import RegisterForm from './RegisterForm'

const Hero = () => {
    return (
      <main className={cn(styles.herosection)} id="hero">
        <section className={cn(styles.herosection__bgimage)}>
          <div className={"overlay"}></div>
        </section>
        <section className={cn(styles.herosection__content)}>
          <section className={cn(styles.herosection__text)}>
            <HeroSectionText type="hero" />
            <GetAppLink />
          </section>
          <section className={cn(styles.herosection__formcontainer)}>
            <RegisterForm />
          </section>
        </section>
      </main>
    );
}

export default Hero
