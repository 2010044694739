import cn from "classnames";
import { CloudinaryImageHelper } from "../image-helper";
import ReactPlayer from "react-player/youtube";
import { Link } from "react-router-dom"
import { PurpleButton } from "../buttons"

import styles from "./Cards.module.css";

const CardWithIconAndHeading = ({ icon, text }) => {
  return (
    <div className={cn(styles["card-icon-h"])}>
      <CloudinaryImageHelper
        publicId={`icons/${icon}`}
        width="55"
        height="55"
        alt={icon}
      />
      <h2 className={cn(styles["card-icon-h__headingtext"])}>{text}</h2>
    </div>
  );
};

const CardWithIconHeadingSubheading = ({
  icon,
  heading,
  subheading,
  index,
}) => {
  return (
    <div className={cn(styles["card-icon-h-sh"])}>
      <CloudinaryImageHelper
        publicId={`icons/${icon}`}
        width="60"
        height="60"
        className="mt-05"
        alt={icon}
      />
      <div className={cn(styles["card-icon-h-sh__text"])}>
        <p className={cn(styles["card-icon-h-sh__subheading"])}>
          {heading["top_start"] && heading["top_start"]}
          {heading["highlight_text"] !== "" && (
            <span
              className={cn(
                styles["highlight"],
                styles["highlight--purple"],
                index === 0 && styles["highlight--red"]
              )}
            >
              {heading["highlight_text"]}
            </span>
          )}{" "}
          {heading["top_end"] !== "" && heading["top_end"]} {heading["bottom"]}{" "}
          {subheading["top_start"] && subheading["top_start"]}{" "}
          {subheading["highlight_text"] !== "" && (
            <span
              className={cn(styles["highlight"], styles["highlight--purple"])}
            >
              {subheading["highlight_text"]}
            </span>
          )}{" "}
          {subheading["top_end"] !== "" && subheading["top_end"]}{" "}
          {subheading["bottom"]}
        </p>
      </div>
    </div>
  );
};

const TeacherCard = ({ teacherInfo }) => {
  const { imgUrl, name, subjects, experience, expertAt, education } =
    teacherInfo;
  return (
    <div className={cn(styles["card-teacherinfo"])}>
      <CloudinaryImageHelper
        publicId={`teachers/${imgUrl}`}
        width="150"
        height="150"
        alt={`teacher-photo ${name}`}
        className="round"
      />
      <p className={cn(styles["card-teacherinfo__name"])}>{name}</p>
      <p className={cn(styles["card-teacherinfo__designation"])}>{subjects}</p>
      <p className={cn(styles["card-teacherinfo__experience"])}>{experience}</p>
      <p className={cn(styles["card-teacherinfo__expert-at"])}>{expertAt}</p>
      <p className={cn(styles["card-teacherinfo__education"])}>{education}</p>
    </div>
  );
};

const MetricsCard = ({ number, message }) => {
  return (
    <div className={styles["metric-card"]}>
      <p className={cn(styles["metric-card__number"])}>{number}+</p>
      <p className={cn(styles["metric-card__message"])}>
        {message.top !== "" && message.top}{" "}
        {message.middle !== "" && message.middle}{" "}
        {message.top !== "" && message.bottom}
      </p>
    </div>
  );
};

const SpokenEnglishCard = ({ icon, heading, subheading }) => {
  return (
    <div className={cn(styles["metric-card"], styles["spkeng-card"])}>
      {typeof heading === "string" ? (
        <p className={cn(styles["spkeng-card__text"])}>{heading}</p>
      ) : (
        <Link to={heading.path} className={cn(styles["spkeng-card__text"])}>
          <PurpleButton>{heading.text}</PurpleButton>
        </Link>
      )}
      <CloudinaryImageHelper publicId={`/icons/${icon}`} />
      <p className={cn(styles["metric-card__message"])}>{subheading}</p>
    </div>
  );
};

const ProgressCard = ({ icon, heading, subheading }) => {
  return (
    <div className={cn(styles["metric-card"], styles["progress-card"])}>
      <CloudinaryImageHelper publicId={`icons/${icon}`} alt={icon} />
      <div className={styles["progress-card__text"]}>
        <p className={cn(styles["progress-card__heading"])}>{heading}</p>
        <p className={cn(styles["progress-card__subheading"])}>{subheading}</p>
      </div>
    </div>
  );
};

const FeatureIconCard = ({ icon, text }) => {
  return (
    <div className={cn(styles["featicon-card"])}>
      <p>{text}</p>
      <CloudinaryImageHelper publicId={`icons/${icon}`} />
    </div>
  );
};

const HighlightIconCard = ({ icon, text }) => {
  return (
    <div className={cn(styles["hglticon-card"])}>
      <p>{text}</p>
      <CloudinaryImageHelper publicId={`icons/${icon}`} />
    </div>
  );
};

const PricingCard = ({
  plan,
  base_price,
  total_class,
  per_class,
  per_month,
  desc,
}) => {
  return (
    <div className={cn(styles["pricingcard"])}>
      <h2>{plan}</h2>
      <h3>₹ {base_price}</h3>
      {total_class && (
        <p>
          {" "}
          <span>{total_class.text}: </span>
          {total_class.number}
        </p>
      )}
      {per_month && (
        <p>
          {" "}
          <span>{per_month.text}: </span> ₹ {per_month.price}{" "}
        </p>
      )}
      {per_class && (
        <p>
          {" "}
          <span>{per_class.text}: </span> ₹ {per_class.price}{" "}
        </p>
      )}
      <div>
        <p>{desc.line1}</p>
        <p>{desc.line2}</p>
      </div>
    </div>
  );
};

const CircleCard = ({ icon1, icon2, text }) => {
  return (
    <div className={cn(styles["circlecard"])}>
      <CloudinaryImageHelper
        publicId={`icons/${icon1}`}
        className={cn(styles["circlecard-numimg"])}
        alt={icon1}
        height="200px"
      />
      <p>{text}</p>
      <CloudinaryImageHelper
        publicId={`icons/${icon2}`}
        alt={icon1}
        height="50px"
        width="50px"
        className="mt-1"
      />
    </div>
  );
};

const TestimonialCard = ({ videoUrl, name }) => {
  return (
    <div className={cn(styles["card-testimonial"])}>
      <div className={cn(styles["card-testimonial__data"])}>
        <div className={cn(styles["card-testimonial__video"])}>
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            controls={true}
            config={{
              youtube: {
                playerVars: { origin: window.location.origin },
              },
            }}
          />
        </div>
        <div className={cn(styles["card-testimonial__detail"])}>
          <p className={cn(styles["card-testimonial__name"])}>{name}</p>
        </div>
      </div>
    </div>
  );
};

const TeacherTestimonialCard = ({ imgUrl, name, text, designation }) => {
  return (
    <div className={cn(styles["card-testimonial"])}>
      <div className={cn(styles["card-testimonial__data"])}>
        <div className={cn(styles["card-testimonial__img"])}>
          <img src={imgUrl} alt="" />
        </div>
        <div className={cn(styles["card-testimonial__detail--teacher"])}>
          <p className={cn(styles["card-testimonial__text"])}>{text}</p>
          <p className={cn(styles["card-testimonial__name"])}>{name}</p>
          <p className={cn(styles["card-testimonial__desg"])}>{designation}</p>
        </div>
      </div>
    </div>
  );
};

export {
  CardWithIconAndHeading,
  CardWithIconHeadingSubheading,
  TeacherCard,
  MetricsCard,
  TestimonialCard,
  SpokenEnglishCard,
  ProgressCard,
  FeatureIconCard,
  HighlightIconCard,
  PricingCard,
  CircleCard,
  TeacherTestimonialCard
};
