import { useState } from "react";
import { HeroSectionText } from "../../home";
import { useLanguage } from "../../language-content";
import cn from "classnames";
import { CloudinaryImageHelper } from "../../utils/image-helper";
import { YellowButton, IconButton } from "../../utils/buttons";
import { faChevronDown, faChevronRight } from "../../utils/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import herostyles from "../../home/hero/Hero.module.css";
import styles from "./TeachWithUs.module.css";
import { CardWithIconAndHeading, CircleCard } from "../../utils/cards";
import Testimonials from "../../home/homepage_sections/MetricsAndTestimonials/Testimonials";
import { gaButtonClickHandler } from "../../utils/google-analytics";

const TeachWithUs = () => {
  const { languageData } = useLanguage();

  const { why_learnhat, steps, faq } = languageData["teach_with_us"];

  return (
    <>
      <main
        className={cn(herostyles.herosection, styles.herosection)}
        id="form"
      >
        <section
          className={cn(
            herostyles.herosection__bgimage,
            styles.herosection__bgimage
          )}
        ></section>
        <section className={cn(herostyles.herosection__content, styles.test)}>
          <section className={cn(herostyles.herosection__text)}>
            <HeroSectionText type="teach_with_us" />
            <div className={cn(styles["twu__hero-cta"], "mt-2")}>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfB7-HnRIOJ6lkrFztBDHO2oolT_S5zN0a6YQoDko-B7hPYxw/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <YellowButton onClick={() => gaButtonClickHandler("Button","Apply Now Button(Teach with us)", window.location.pathname)} >Apply Now</YellowButton>
              </a>
            </div>
          </section>
          <section className={cn(styles.imagecontainer)}>
            <div className={cn(styles["herosection__image"])}>
              <CloudinaryImageHelper publicId="techwithus-header-img" />
            </div>
          </section>
        </section>
      </main>
      <div className={cn(styles["twu__why"])}>
        <h1 className="heading">{why_learnhat.heading}</h1>
        <div className={cn(styles["twu__whycards"])}>
          {why_learnhat.cards.map(({ icon, text }) => {
            return <CardWithIconAndHeading icon={icon} text={text} />;
          })}
        </div>
      </div>
      <div className={cn(styles["twu__steps"])}>
        <h1 className="heading2">{steps.heading}</h1>
        <div className={cn(styles["twu__steps-cards"])}>
          {steps.cards.map(({ icon1, icon2, text }) => {
            return <CircleCard icon1={icon1} icon2={icon2} text={text} />;
          })}
        </div>
      </div>
      <div>
        <Testimonials type="teacher" />
        <div className="mt-1 flex jc-center">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfB7-HnRIOJ6lkrFztBDHO2oolT_S5zN0a6YQoDko-B7hPYxw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <YellowButton 
            onClick={() => gaButtonClickHandler("Button","Apply Now Button(Teach with us)", window.location.pathname)}
            >Apply Now</YellowButton>
          </a>
        </div>
      </div>
      <div className={cn(styles["twu__faq"])}>
        <h1 className="heading">{faq.heading}</h1>
        <div className={cn(styles["twu__faq-container"])}>
          {faq.questions.map(({ question, answer }) => {
            return <FAQCard question={question} answer={answer} />;
          })}
        </div>
      </div>
    </>
  );
};

const FAQCard = ({ question, answer }) => {
  const [showFaqCard, setShowFaqCard] = useState(false);

  function handleButtonClick(e) {
    setShowFaqCard(!showFaqCard);
  }

  return (
    <div className={cn(styles["twu__faq-card"])} onClick={handleButtonClick}>
      <div className={cn(styles["twu__faq-question"])}>
        <div className={cn(styles["twu__faq-question-container"])}>
          <h1 className={cn(styles["twu__faq-question-text"])}>{question}</h1>
        </div>
        <IconButton onClick={handleButtonClick} className="color--black">
          <FontAwesomeIcon
            icon={showFaqCard ? faChevronRight : faChevronDown}
          />
        </IconButton>
      </div>
      <div
        className={cn(
          styles["twu__faq-answer"],
          showFaqCard && styles["answer-active"]
        )}
      >
        {typeof answer === "string" ? (
          <p key={`answer`} className={cn(styles["twu__faq-answertext"])}>
            {answer}
          </p>
        ) : (
          <>
            <p>{answer["ul_head"]}</p>
            <ul className="mt-05">
              { answer.points.map(point => <li>{point}</li>) }
            </ul>
            <p>{answer["desc"]}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default TeachWithUs;