import { useContext, useReducer, createContext } from "react";
import { courseDetailReducer } from ".";

const CourseDetailContext = createContext();

const initialState = {
  selectedGrade: [],
  selectedSubject: [],
  selectedBoard: [],
  selectedBatch: "",
  selectedPlan: {
    subject_plan_id: "",
    subject_price: "",
    subject_price_duration: "",
    subject_price_duration_type_value: "",
  },
  phoneNumber: "",
  otp: "",
  otpToken: "",
  pe: "",
  tp: "",
  otpVerifiedToken: "",
};

export const CourseDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(courseDetailReducer, initialState);

  return (
    <CourseDetailContext.Provider
      value={{ courseDetailState: state, courseDetailDispatch: dispatch }}
    >
      {children}
    </CourseDetailContext.Provider>
  );
};

export const useCourseDetail = () => useContext(CourseDetailContext);
