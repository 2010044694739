import { HeroSectionText, RegisterForm } from "../../home";
import { useLanguage } from "../../language-content";
import cn from "classnames";
import { CloudinaryImageHelper } from "../../utils/image-helper";
import {
  FeatureIconCard,
  HighlightIconCard,
  PricingCard,
  ProgressCard,
  SpokenEnglishCard,
} from "../../utils/cards";
import { Link } from "react-router-dom";

import herostyles from "../../home/hero/Hero.module.css";
import styles from "./SpokenEnglish.module.css";
import { PurpleButton } from "../../utils/buttons";

const SpokenEnglish = () => {
  const { languageData } = useLanguage();

  const { learn_better, progress_ladder, features, pricing } =
    languageData["spoken_english"];

  return (
    <>
      <main className={cn(herostyles.herosection, styles.herosection)} id="form">
        <section
          className={cn(
            herostyles.herosection__bgimage,
            styles.herosection__bgimage
          )}
        ></section>
        <section className={cn(herostyles.herosection__content)}>
          <section className={cn(herostyles.herosection__text)}>
            <HeroSectionText type="spoken_english" />
            <div className={cn(styles["herosection__image"])}>
              <CloudinaryImageHelper publicId="/spokeneng-youth" width="75%" />
            </div>
          </section>
          <section className={cn(herostyles.herosection__formcontainer)}>
            <RegisterForm type={"spoken-english"} />
          </section>
        </section>
      </main>
      <div className="flex al-center jc-center column p-1">
        <h1 className="heading mt-1">{learn_better.title}</h1>
        <div className={cn(styles["spokeneng__learn-better"])}>
          {learn_better.cards.map(({ icon, heading, subheading }) => {
            return (
              <SpokenEnglishCard
                icon={icon}
                heading={heading}
                subheading={subheading}
              />
            );
          })}
        </div>
        <Link to="#form" className="mt-2">
          <PurpleButton>{learn_better["cta"]}</PurpleButton>
        </Link>
      </div>
      <div className={cn(styles["spokeneng__progress"])}>
        <h1 className="heading">{progress_ladder.title.heading}</h1>
        <h2 className="subheading">{progress_ladder.title.subheading}</h2>
        <div className={cn(styles["spokeneng__progress-content"])}>
          <div className={cn(styles["spokeneng__prgcards"])}>
            <img
              src="https://res.cloudinary.com/learnhat/image/upload/v1632748815/website_images/icons/progress-line2.png"
              alt="progress-line"
              className={cn(styles["floating-prgline"])}
            />
            {progress_ladder.left_cards.map(({ icon, heading, subheading }) => {
              return (
                <ProgressCard
                  icon={icon}
                  heading={heading}
                  subheading={subheading}
                />
              );
            })}
          </div>
          <div className={cn(styles["spokeneng__prgboard"])}>
            <div className={cn(styles["spokeneng__prgboard-content"])}>
              <h2>{progress_ladder.right_points.title}</h2>
              <div className={cn(styles["spokeneng__keyfeatures"])}>
                {progress_ladder.right_points.points.map((point) => {
                  return (
                    <div>
                      <CloudinaryImageHelper
                        publicId={"/icons/course-details-head-arrow"}
                        alt="course-details-head-arrow"
                      />
                      <p>{point}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles["spokeneng__keyfeat"])}>
        <div>
          <p>{features.focus.heading}</p>
          <h2>{features.focus.subheading}</h2>
          <div className={cn(styles["spokeneng__keyfeat-cards"])}>
            {features.focus.cards.map(({ icon, text }) => {
              return <FeatureIconCard icon={icon} text={text} />;
            })}
          </div>
        </div>
        <div>
          <p>{features.highlight.heading}</p>
          <h2>{features.highlight.subheading}</h2>
          <div className={cn(styles["spokeneng__hglt-cards"])}>
            {features.highlight.cards.map(({ icon, text }) => {
              return <HighlightIconCard icon={icon} text={text} />;
            })}
          </div>
        </div>
      </div>
      <div className={cn(styles["spokeneng__pricing"])}>
        <h1 className="heading">{pricing.heading}</h1>
        <p className="subheading">{pricing.subheading}</p>
        <div className={cn(styles["spokeneng__pricingcards"])}>
          {pricing.plans.map(
            ({ plan, base_price, total_class, per_month, per_class, desc }) => {
              return (
                <PricingCard
                  plan={plan}
                  base_price={base_price}
                  total_class={total_class}
                  per_month={per_month && per_month}
                  per_class={per_class}
                  desc={desc}
                />
              );
            }
          )}
        </div>
        <div
          className={cn(
            styles["spokeneng__keyfeatures"],
            styles["spokeneng__pricingfeats"]
          )}
        >
          {pricing.keypoints.map((point) => {
            return (
              <div>
                <CloudinaryImageHelper
                  publicId={"/icons/course-details-head-arrow"}
                  alt="course-details-head-arrow"
                />
                <p>{point}</p>
              </div>
            );
          })}
        </div>
        <div className="mt-2 flex jc-center">
          <Link to="#form">
            <PurpleButton>{learn_better["cta"]}</PurpleButton>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SpokenEnglish;
