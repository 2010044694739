import cn from "classnames";
import { useLanguage } from "../../../language-content";
import { CardWithIconHeadingSubheading } from "../../../utils/cards";

import styles from "./WhyLearnhat.module.css";

const WhyLearnhat = () => {
  const { languageData } = useLanguage();
  const whyLearnhatData = languageData["why_learnhat"];
  return (
    <section className={cn(styles["why-learnhat"])}>
      <h1 className={cn(styles["why-learnhat__heading"])}>{whyLearnhatData["why_learnhat_heading"]}</h1>
      <div className={cn(styles["why-learnhat__cards"])}>
        {whyLearnhatData.cards.map(({ icon, heading, subheading }, index) => {
          return (
            <CardWithIconHeadingSubheading
              key={`card${index}`}
              icon={icon}
              heading={heading}
              subheading={subheading}
              index={index}
            />
          );
        })}
      </div>
    </section>
  );
};

export default WhyLearnhat;
