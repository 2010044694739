/* eslint-disable */
import { useEffect } from "react"
import { convertToEncryption } from "../../utils/crypto";
import axios from "axios";
import cn from "classnames";
import styles from "./Login.module.css";
import { GetAppLink } from "../../home";
import { InputText } from "../../utils/input";
import { PurpleButton } from "../../utils/buttons";
import { useCourseDetail } from "../courses";
import { useNavigate } from "react-router-dom"

const EnterOTP = () => {
  const { courseDetailState, courseDetailDispatch } = useCourseDetail();

  const { otp, phoneNumber, otpToken } = courseDetailState;

  let passingObjectOTP = {
    p_number: phoneNumber,
    v_otp: otp,
  };

  let encryptedTextToSendOTP = convertToEncryption(passingObjectOTP);

  let bodyFormData = new FormData();

  bodyFormData.append("param1", encryptedTextToSendOTP);

  const navigate = useNavigate()

  useEffect(() => {
    if(phoneNumber === ""){
      navigate("/coursesk12");
    }
  }, [])

  const handleButtonClick = async () => {
    try {
      const { status, data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/validateOtpWithToken`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${otpToken}`,
        },
      });
      if (status === 200 && data.success === "1") {
        courseDetailDispatch({
          type: "SET_TP",
          payload: { tp: data.tp },
        });
        courseDetailDispatch({
          type: "SET_PE",
          payload: { pe: data.pe },
        });
        navigate("/buy-course")
      }
    } catch (error) {
      console.log(error);
    }
  };

  let passingObjectResend = {
    booker_phone: phoneNumber,
  };

  let encryptedTextToResendOTP = convertToEncryption(passingObjectResend);

  let bodyFormData2 = new FormData();

  bodyFormData2.append("param1", encryptedTextToResendOTP);

  const handleResendClick = async () => {
    try {
      const { status, data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/onSendBookingOTP`,
        data: bodyFormData2,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (status === 200 && data.success === "1") {
        courseDetailDispatch({
          type: "SET_OTP_TOKEN",
          payload: { otpToken: data.token },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={cn(styles["login"])}>
        <div className={cn(styles["login__content"])}>
          <div className={cn(styles["login__content-left"])}>
            <h1 className={cn(styles["login__header"])}>
              Excel in your education <br />
              journey with <br />
              <span className={cn(styles["login__header-large"])}>
                LearnHat{" "}
                <span className={cn(styles["login__header-highlight"])}>
                  LIVE
                </span>{" "}
                <br />
                Learning{" "}
              </span>
            </h1>
          </div>
          <div className={cn(styles["login__content-right"])}>
            <div className={cn(styles["login__input"])}>
              <h1>Enter 4 Digit OTP</h1>
              <InputText
                type="number"
                value={otp}
                placeholder="4 Digit OTP"
                onChange={(e) =>
                  courseDetailDispatch({
                    type: "SET_OTP",
                    payload: { otp: e.target.value },
                  })
                }
              />
              <PurpleButton onClick={handleButtonClick}>Continue</PurpleButton>
              <p className="mt-1" onClick={handleResendClick}>
                <u>Resend OTP</u>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles["login__get-applink"])}>
        <GetAppLink mt={"none"} />
      </div>
    </>
  );
};

export default EnterOTP;
