import cn from "classnames"
import { useLanguage } from "../../../language-content"
import { TeacherCard } from "../../../utils/cards"
import { PurpleButton } from "../../../utils/buttons"
import { Link } from "react-router-dom"

import styles from "./BestTeachers.module.css"

const BestTeachers = () => {

    const { languageData } = useLanguage()
    const bestTeachersData = languageData["best_teachers"]

    return (
      <section className={cn(styles["best-teachers"])}>
        <h1 className={cn(styles["best-teachers__heading"])}>
          {bestTeachersData["heading"]}
        </h1>
        <div className={cn(styles["best-teachers__display"])}>
          {bestTeachersData["teachers"].map((teacher) => {
            return <TeacherCard key={teacher.name} teacherInfo={teacher} />;
          })}
        </div>
        <div className={cn(styles["best-teachers__cta"])}>
          <Link to="/#hero">
            <PurpleButton btnType="btn--large">
              {bestTeachersData["cta2"]}
            </PurpleButton>
          </Link>
        </div>
      </section>
    );
}

export default BestTeachers
