import { useState } from "react";
import MobileNavbar from "./MobileNavbar";
import Navbar from "./Navbar";

const Header = ({ pathname }) => {
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);

  function handleMenuToggle() {
    setMobileMenuToggle(!mobileMenuToggle);
  }

  return (
    <header>
      <Navbar pathname={pathname} handleMenuToggle={handleMenuToggle} />
      <MobileNavbar
        mobileMenuToggle={mobileMenuToggle}
        handleMenuToggle={handleMenuToggle}
      />
    </header>
  );
};

export default Header;
