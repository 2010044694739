import cn from "classnames";
import styles from "./Courses.module.css";
import { useLanguage } from "../../language-content";
import { CloudinaryImageHelper } from "../../utils/image-helper";
import { PurpleButton } from "../../utils/buttons";
import { Link } from "react-router-dom";
import { SpokenEnglishCard } from "../../utils/cards";

const CoursesAdultLearner = () => {
  const { languageData } = useLanguage();

  const { features, subjects, cta } = languageData["courses_adult_learner"];

  const subjectsData = subjects["subjects"];

  return (
    <div className={cn(styles["courses"])}>
      {features.length > 0 && (
        <div className={styles["courses__features"]}>
          <h2 className={cn(styles["courses__badges"])}>
            <span className={cn("pill-badge", "bgcolor--red")}>
              Live Classes
            </span>
            <span className={cn("pill-badge", "bgcolor--yellow")}>
              Video Content
            </span>
            <span className={cn("pill-badge", "bgcolor--lightblue")}>
              Doubt Clearing
            </span>
          </h2>
          <div>
            <div className={cn(styles["courses__keyfeatures"])}>
              <h2>Key Features:</h2>
              {features.length > 0 &&
                features.map((feature) => {
                  return (
                    <div key={feature}>
                      <CloudinaryImageHelper
                        publicId={"/icons/course-details-head-arrow"}
                        alt="course-details-head-arrow"
                      />
                      <p>{feature}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <div className={cn(styles["courses__content2"])}>
        <h1 className="heading">{subjects["title"]}</h1>
        <div className={cn(styles["courses__card-container"])}>
          {subjectsData.map((subject) => {
            return (
              <SpokenEnglishCard
                heading={subject.name}
                icon={subject.icon}
                subheading={subject.course_starting}
              />
            );
          })}
        </div>
        <div className="mt-2 flex al-center column">
          <Link to="/#hero">
            <PurpleButton>{cta}</PurpleButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CoursesAdultLearner;
