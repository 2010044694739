/* eslint-disable */

import { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Hero.module.css";
import { InputText, InputSelect, InputMobileNumber } from "../../utils/input";
import { PurpleButton } from "../../utils/buttons";
import {
  validateName,
  validateClass,
  validatePhoneNo,
  validateSubject,
} from "../../utils/validation";
import axios from "axios";
import { convertToEncryption } from "../../utils/crypto";
import {gaButtonClickHandler} from '../../utils/google-analytics';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const RegisterForm = ({ type }) => {
  const [grades, setGrades] = useState([]);
  const [name, setName] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [category, setCategory] = useState("k12");
  const [termsAgreed, setTermsAgreed] = useState(true);

  const [nameValidationMsg, setNameValidationMsg] = useState("");
  const [phoneValidationMsg, setPhoneValidationMsg] = useState("");
  const [classValidationMsg, setClassValidationMsg] = useState("");
  const [subjectValidationMsg, setSubjectsValidationMsg] = useState("");

  useEffect(() => {
    getGradeList();
  }, []);

  function handleNameInput(e) {
    setName(e.target.value);
  }

  function handleMobNoInput(e) {
    setMobNo(e.target.value);
  }

  const handleGradeSelection = (e) => {
    setSelectedGrade(e.target.value.split(","));
    getSubjectsData(e.target.value.split(",")[1]);
  };

  const handleSubjectSelection = (e) => {
    setSelectedSubject(e.target.value.split(","));
  };

  function handleAgreedTerms(e) {
    e.target.checked ? setTermsAgreed(true) : setTermsAgreed(false);
  }

  function checkName() {
    setNameValidationMsg(validateName(name));
  }

  function checkPhoneNo() {
    setPhoneValidationMsg(validatePhoneNo(mobNo));
  }

  function checkClass() {
    setClassValidationMsg(validateClass(selectedGrade[0]));
  }

  function checkSubject() {
    setSubjectsValidationMsg(validateSubject(selectedSubject[0]));
  }

  const getGradeList = async () => {
    try {
      const { status, data } = await axios.get(`${BACKEND_BASE_URL}/gradeList`);
      if (status === 200) {
        setGrades(data.grades.slice(1, 13));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubjectsData = async (grade) => {
    try {
      const { status, data } = await axios.get(
        `${BACKEND_BASE_URL}/getSubjects/${grade}`
      );
      if (status === 200) {
        setSubjects(data.subjects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function clearAll() {
    setName("");
    setMobNo("");
    setSelectedGrade([]);
    setSelectedSubject([]);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    checkName();
    checkPhoneNo();
    checkClass();
    checkSubject();
    if (
      name &&
      mobNo &&
      selectedGrade.length > 0 &&
      selectedSubject.length > 0 &&
      nameValidationMsg === "" &&
      phoneValidationMsg === "" &&
      classValidationMsg === "" &&
      subjectValidationMsg === ""
    ) {
      submitData(e);
    }
  }

  function submitData(e) {
    (async () => {
      try {
        let student = {
          student_name: name,
          phone_number: mobNo,
          selected_class: selectedGrade[0],
          student_subject: type === "spoken-english" ? "100" : selectedSubject[0],
        };

        const encryptedTextToSend = convertToEncryption(student);

        var bodyFormData = new FormData();

        bodyFormData.append("param1", encryptedTextToSend);

        const { status, data } = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_BASE_URL}/learnhatstudentRegistrationFrom`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (status === 200 && data.success === "1") {
          alert("Form submitted. Our team will get in touch with you soon.");
          clearAll();
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }

  function preventSubmit(e){
    e.preventDefault()
  }

  function handleAdultLearnerClick(){
    setCategory("adult")
    setSelectedGrade(() => ["16", "Misc"])
    getSubjectsData("16");
  }

  function handleK12Click() {
    setCategory("k12");
    setSelectedGrade(() => []);
    setSelectedSubject(() => []);
  }

  return (
    <form
      onSubmit={preventSubmit}
      className={cn(styles["herosection__form"])}
      method="post"
      encType="multipart/form-data"
    >
      <h1 className={cn(styles["herosection__formheading"])}>
        {type === "spoken-english"
          ? "Book Your Free Spoken English Trial Class Today!"
          : "Book Your Free Trial Class Today!"}
      </h1>
      <div className={cn(styles["herosection__categories"])}>
        <PurpleButton
          className={category !== "k12" && "btn-transparent--purple"}
          onClick={handleK12Click}
        >
          Class 1-12
        </PurpleButton>
        <PurpleButton
          className={category !== "adult" && "btn-transparent--purple"}
          onClick={handleAdultLearnerClick}
        >
          Adult Learner
        </PurpleButton>
      </div>
      <InputText
        type="text"
        id="FirstName"
        name="FirstName"
        maxlength="100"
        autocomplete="off"
        placeholder="Name"
        className="mt-05"
        value={name}
        onChange={handleNameInput}
        onBlur={checkName}
      />
      {nameValidationMsg && (
        <p className="validation-message">{nameValidationMsg}</p>
      )}
      <br />
      <InputMobileNumber
        type="text"
        id="Phone"
        name="Phone"
        maxlength="50"
        autocomplete="off"
        placeholder="Mobile number"
        className="mt-05"
        value={mobNo}
        onChange={handleMobNoInput}
        onBlur={checkPhoneNo}
        width="100%"
      />
      {phoneValidationMsg && (
        <p className="validation-message">{phoneValidationMsg}</p>
      )}
      {category === "k12" && (
        <>
          <InputSelect
            onChange={(e) => handleGradeSelection(e)}
            className="mt-05"
          >
            <option value="" selected hidden onBlur={checkClass}>
              Select Grade
            </option>
            {grades.map(({ class_id, class_name }) => {
              return (
                <option key={class_id} value={[class_id, class_name]}>
                  {class_name}
                </option>
              );
            })}
          </InputSelect>
          {classValidationMsg && (
            <p className="validation-message">{classValidationMsg}</p>
          )}
        </>
      )}
      {type === "spoken-english" ? (
        <InputText
          type="text"
          value="Spoken English"
          disabled={true}
          className="mt-05"
        />
      ) : (
        selectedGrade.length > 0 && (
          <InputSelect
            onChange={handleSubjectSelection}
            className="mt-05"
            onBlur={checkSubject}
          >
            <option value="" selected hidden>
              Select Subject
            </option>
            {subjects.map(({ subject_id, subject_name }) => (
              <option key={subject_id} value={[subject_id, subject_name]}>
                {subject_name}
              </option>
            ))}
          </InputSelect>
        )
      )}
      {subjectValidationMsg && (
        <p className="validation-message">{subjectValidationMsg}</p>
      )}
      <br />
      <span className="tc">
        <span className="tc-input">
          <input
            type="checkbox"
            id="MailingPreferences"
            name="MailingPreferences"
            checked={termsAgreed}
            onChange={handleAgreedTerms}
            className="mr-05"
          />
          <label htmlFor="MailingPreferences">
            I agree to the LearnHat's{" "}
            <a
              href="/terms"
              target="_blank"
              rel="noreferrer"
              className="color--blue"
            >
              Terms and Conditions
            </a>{" "}
            &{" "}
            <a
              href="/privacy"
              target="_blank"
              rel="noreferrer"
              className="color--blue"
            >
              Privacy Policy
            </a>
            {"."}
          </label>
        </span>
      </span>
      <br />
      <PurpleButton
        onClick={handleSubmit, category === "adult" ? () => gaButtonClickHandler("Button", "Trial Class From Submit(Adult)", window.location.pathname) 
        : 
        () => gaButtonClickHandler("Button", "Trial Class From Submit(K12)", window.location.pathname)}
        id="form-submit-button"
        type="submit"
        btnType="btn--large"
      >
        Submit
      </PurpleButton>
      <br />
    </form>
  );
};

export default RegisterForm;
