import React from "react";
import { CloudinaryImageHelper } from "../../../utils/image-helper";
import { useLanguage } from "../../../language-content";
import { Link } from "react-router-dom"
import cn from "classnames"

import styles from "./Sections.module.css"

const Sections = () => {
  const { languageData } = useLanguage();
  const aboutLearnhatData = languageData["about_learnhat"];

  return (
    <section className={cn(styles.sections)}>
      {aboutLearnhatData["section_cards"].map(
        ({ image, card_heading, card_subheading, path }) => {
          return (
            <div key={card_heading} className={cn(styles["sections__type"])}>
              <CloudinaryImageHelper
                className="border-round"
                publicId={image}
                alt="students"
                width="90%"
                height="ih"
              />
              <Link to={path}>
                <div className={cn(styles["sections__textfloater"])}>
                  <h1 className={cn(styles["section__heading"])}>
                    {card_heading}
                  </h1>
                  <p className={cn(styles["section__subheading"])}>
                    {card_subheading.top}
                    <br />
                    {card_subheading.bottom}
                  </p>
                </div>
              </Link>
            </div>
          );
        }
      )}
    </section>
  );
};

export default Sections;
