import { useLanguage } from "../../language-content"
import cn from "classnames"

import styles from "./Privacy.module.css"

const Privacy = () => {

    const { languageData } = useLanguage()

    const { title, introduction, points } = languageData["privacy"]

    return (
      <div className={cn(styles["privacy"])}>
        <h1>{title}</h1>
        {introduction.map((intro, index) => {
          return <p key={index}>{intro}</p>;
        })}
        <ol>
          {points.map((point) => {
            return (
              <li>
                <p className={cn(styles["privacy__list-heading"])}>{point.title}</p>
                {point.data.map((dataItem) => {
                  return typeof dataItem === "string" ? (
                    <p>{dataItem}</p>
                  ) : (
                    <>
                      <p>{dataItem.text}</p>
                      <ul>
                        {dataItem.subpoints.map((subpoint) => {
                          return <li>{subpoint}</li>;
                        })}
                      </ul>
                    </>
                  );
                })}
              </li>
            );
          })}
        </ol>
      </div>
    );
}

export default Privacy
