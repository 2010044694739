import { useState } from "react";
import cn from "classnames";
import { PurpleButton, BlueButton } from "../../utils/buttons";
import { InputMobileNumber } from "../../utils/input";
import { Link } from "react-router-dom";
import { useLanguage } from "../../language-content";
import { convertToEncryption } from "../../utils/crypto"
import axios from "axios"
import { gaButtonClickHandler } from "../../utils/google-analytics";

import styles from "./Hero.module.css";

const GetAppLink = ({mt, version}) => {

  const [phoneNumber, setPhoneNumber] = useState("")

  const { languageData } = useLanguage();
  const getAppLinkData = languageData["get_app_link"];

  const passingObject = {
    booker_phone: phoneNumber
  }

  const encryptedTextToSend = convertToEncryption(passingObject)

  var bodyFormData = new FormData();

  bodyFormData.append("param1", encryptedTextToSend);

  const handleClick = async () => {
    try {
      const { status, data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/sendDLink`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if(status === 200 && data.success === "1"){
        alert("Link sent!")
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <article
      className={cn(
        styles.herosection__getapplink,
        mt === "none" && styles["herosection__getapplink--nomt"],
        version === "blue" && styles["herosection__getapplink--blue"]
      )}
    >
      <div className={cn(styles["herosection__getapplink-input"])}>
        <p>{getAppLinkData["get_app_link_heading"]}</p>
        <div className={cn(styles["herosection__getapplink-bottom"])}>
          <div className={cn(styles["herosection__getapplink-inputcontainer"])}>
            <InputMobileNumber
              height="50"
              type="number"
              placeholder={getAppLinkData["get_app_link_input_placeholder"]}
              className="small-input"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {version === "blue" ? (
              <BlueButton className="ml-05 small-btn" onClick={() => {
                handleClick()
                gaButtonClickHandler("Button","Get Link Play Store", "From Footer")
              }} >
                {getAppLinkData["get_app_link_cta"]}
              </BlueButton>
            ) : (
              <PurpleButton className="ml-05 small-btn" onClick={() => {
                handleClick()
                gaButtonClickHandler("Button","Get Link Play Store", "From Hero section")
              }}>
                {getAppLinkData["get_app_link_cta"]}
              </PurpleButton>
            )}
          </div>
          <Link
            to="https://play.google.com/store/apps/details?id=com.orionedutech.orionlms"
            className="mt-07 ml-05"
          >
            <img
              src="https://res.cloudinary.com/learnhat/image/upload/v1628530372/website_images/googlePlayStore.svg"
              alt="google-playstore-link"
              height="50px"
            />
          </Link>
        </div>
      </div>
    </article>
  );
};

export default GetAppLink;
