export function validateName(name) {
  if (name !== "") {
    let res = /^[a-zA-Z ]+$/.test(name);
    if (res) {
      return "";
    } else {
      return "Use only letters for name";
    }
  } else {
    return "Name is required";
  }
}

function checkEmailFormat(email) {
  const res =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      String(email).toLowerCase()
    );
  return res;
}

export function validateEmail(email) {
  if (email !== "") {
    if (checkEmailFormat(email)) {
      return "";
    } else {
      return "Enter a valid email";
    }
  } else {
    return "Email is required";
  }
}

function checkPhoneNoFormat(phoneNo) {
  if (phoneNo.length >= 10) {
    const res = /^[0-9]+$/.test(phoneNo);
    return res;
  } else return false;
}

export function validatePhoneNo(phoneNo) {
  if (phoneNo !== "") {
    if (checkPhoneNoFormat(phoneNo)) {
      return "";
    } else {
      return "Enter a valid phone number";
    }
  } else {
    return "Phone number is required";
  }
}

export function validateClass(classStudying) {
  if (classStudying !== "") {
    return "";
  } else {
    return "Class is required";
  }
}

export function validateAge(age) {
  if (age !== "") {
    return "";
  } else {
    return "Age is required";
  }
}

export function validateCity(city) {
  if (city !== "") {
    return "";
  } else {
    return "City is required";
  }
}

export function validateState(stateFrom) {
  if (stateFrom !== "") {
    return "";
  } else {
    return "State is required";
  }
}

export function validateGrade(grade) {
  if (grade !== "") {
    return "";
  } else {
    return "Select a grade";
  }
}

export function validateSubject(subject) {
  if (subject !== "") {
    return "";
  } else {
    return "Select a subject";
  }
}

export function validateBoard(board) {
  if (board !== "") {
    return "";
  } else {
    return "Select a board";
  }
}

export function validateBatch(batch) {
  if (batch !== "") {
    return "";
  } else {
    return "Select a batch";
  }
}

export function validatePlan(plan) {
  if (plan !== "") {
    return "";
  } else {
    return "Select a plan";
  }
}
