import { Carousel } from "react-responsive-carousel";
import { TeacherTestimonialCard, TestimonialCard } from "../../../utils/cards";
import { useLanguage } from "../../../language-content";
import { gaButtonClickHandler } from "../../../utils/google-analytics";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialsCarousel = ({ type }) => {

    const {languageData} = useLanguage()
    const { testimonialsData } =
      type === "teacher"
        ? languageData["teach_with_us"]["testimonials"]
        : languageData["student_testimonials"];

  return (
    <div className="carousel-container">
      <Carousel
        className="custom-carousel"
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        {type === "teacher"
          ? testimonialsData.map(({ imgUrl, text, name, designation }) => {
              return (
                <TeacherTestimonialCard
                  imgUrl={imgUrl}
                  text={text}
                  name={name}
                  designation={designation}
                />
              );
            })
          : testimonialsData.map(({ givenBy, videoUrl }) => {
              return (
                <TestimonialCard
                 onClick={() => gaButtonClickHandler("Video","Testimonials Video Played",window.location.pathname )}
                  key={givenBy.name}
                  name={givenBy.name}
                  videoUrl={videoUrl}
                />
              );
            })}
      </Carousel>
    </div>
  );
};

export default TestimonialsCarousel;
