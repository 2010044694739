import { Hero } from "./hero"
import { AboutAndWhyLearnhat, MetricsAndTestimonials } from "./homepage_sections"

const Home = () => {
    return (
        <>
            <Hero />
            <AboutAndWhyLearnhat />
            <MetricsAndTestimonials />
        </>
    )
}

export default Home
