import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { CloudinaryImageHelper } from "../utils/image-helper";
import { useLanguage } from "../language-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPhoneAlt,
  faComments,
  faEnvelope,
} from "../utils/icons";
import { GetAppLink } from "../home/hero"

import styles from "./Links.module.css";

const Links = () => {
  const { languageData } = useLanguage();
  const linksData = languageData["links_and_contact"];

  return (
    <section>
      <div className={cn(styles["links__contact"])}>
        <LogoAndSocial />
        <GetAppLink mt="none" version="blue" />
        <ContactUs linksData={linksData} />
      </div>
      <div className={cn(styles["links__display"])}>
        <LinksDisplay linksData={linksData} />
      </div>
    </section>
  );
};

export default Links;

const LogoAndSocial = () => {
  return (
    <div className={cn(styles["links__social"])}>
      <CloudinaryImageHelper
        publicId={"logo_learnhat"}
        alt="logo_learnhat"
        width="180"
        height="50"
      />
      <div className={cn(styles["links__social-links"])}>
        <a
          href="https://www.facebook.com/learnhat"
          target="_blank"
          rel="noreferrer"
          className={cn(styles["links__socialicon"])}
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://www.instagram.com/learn_hat_/"
          target="_blank"
          rel="noreferrer"
          className={cn(styles["links__socialicon"])}
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://twitter.com/LearnHat"
          target="_blank"
          rel="noreferrer"
          className={cn(styles["links__socialicon"])}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
    </div>
  );
};

// const GetAppLink = ({ linksData }) => {
//   const { title, input_placeholder, cta } = linksData["get_app_link"];
//   return (
//     <div className={cn(styles["links__app-link"])}>
//       <h1 className={cn(styles["links__app-link-heading"])}>{title}</h1>
//       <div className={cn(styles["links__app-link-bottom"])}>
//         <Link to="/">
//           <CloudinaryImageHelper
//             publicId={"googlePlayStore"}
//             alt="google-playstore-applink"
//             width="iw"
//             height="ih"
//             className="mt-02"
//           />
//         </Link>
//         <div className={cn(styles["links__app-link-input"])}>
//           <InputText placeholder={input_placeholder} className="input-black" />
//           <BlueButton className="links-cta">{cta}</BlueButton>
//         </div>
//       </div>
//     </div>
//   );
// };

const ContactUs = ({ linksData }) => {
  const { title } = linksData["contact_us"];
  return (
    <div className={cn(styles["links__contact-details"])}>
      <h1 className={cn(styles["links__contact-heading"])}>{title}</h1>
      <p>
        <FontAwesomeIcon icon={faPhoneAlt} className="mr-05" />
        +91 1800 833 5077
      </p>
      <p>
        <FontAwesomeIcon icon={faComments} className="mr-05" />
        +91 81002 39188
      </p>
      <p>
        <FontAwesomeIcon icon={faEnvelope} className="mr-05" />
        info@learnhat.com
      </p>
    </div>
  );
};

const LinksDisplay = ({ linksData }) => {
  const { quick_links, scholarships, library, more_links1, more_links2 } =
    linksData;

  return (
    <>
      <div className={cn(styles["links__section1"])}>
        <div className={cn(styles["links__quicklinks"])}>
          <h1 className={cn(styles["links__title"])}>{quick_links.title}</h1>
          <h2 className={cn(styles["links__subtitle"])}>
            {quick_links.subtitle}
          </h2>
          <div className={cn(styles["links__column"])}>
            {quick_links.links.set1.map((link) => {
              return (
                <Link
                  key={link.text}
                  className={cn(styles["links__link"])}
                  to={link.path}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
          <div className={cn(styles["links__column"], "mt-2")}>
            {quick_links.links.set2.map((link) => {
              return (
                <Link
                  key={link.text}
                  className={cn(styles["links__link"])}
                  to={link.path}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
          <div className={cn(styles["links__column"], "mt-2")}>
            {quick_links.links.set3.map((link) => {
              return (
                <Link
                  key={link.text}
                  className={cn(styles["links__link"])}
                  to={link.path}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
        </div>
        <div className={cn(styles["links__scholarships"])}>
          <h1 className={cn(styles["links__title"])}>{scholarships.title}</h1>
          <div className={cn(styles["links__column"])}>
            {scholarships.links.map((link) => {
              return (
                <Link
                  key={link.text}
                  className={cn(styles["links__link"])}
                  to={link.path}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className={cn(styles["links__section2"])}>
        <div className={cn(styles["links__library"])}>
          <h1 className={cn(styles["links__title"])}>{library.title}</h1>
          <div className={cn(styles["links__column"])}>
            {library.links.map((link) => {
              return (
                <Link
                  key={link.text}
                  className={cn(styles["links__link"])}
                  to={link.path}
                >
                  {link.text}
                </Link>
              );
            })}
          </div>
        </div>
        <div className={cn(styles["links__column"], styles["links__more1"])}>
          {more_links1.map((link) => {
            return link.path !== undefined ? (
              <Link
                key={link.text}
                className={cn(
                  styles["links__link"],
                  styles["links__link--yellow"]
                )}
                to={link.path}
              >
                {link.text}
              </Link>
            ) : (
              <a
                className={cn(
                  styles["links__link"],
                  styles["links__link--yellow"]
                )}
                href={link.to}
                rel="noreferrer"
                target="_blank"
              >
                {link.text}
              </a>
            );
          })}
        </div>
        <div className={cn(styles["links__column"], styles["links__more2"])}>
          {more_links2.map((link) => {
            return (
              <Link
                key={link.text}
                className={cn(
                  styles["links__link"],
                  styles["links__link--yellow"]
                )}
                to={link.path}
              >
                {link.text}
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};
