import { convertToEncryption } from "../../utils/crypto"
import axios from "axios";
import cn from "classnames"
import styles from "./Login.module.css"
import { GetAppLink } from "../../home";
import { InputText } from "../../utils/input";
import { PurpleButton } from "../../utils/buttons";
import { useCourseDetail } from "../courses"
import { useNavigate } from "react-router-dom";

const LoginWithPhone = () => {
  const { courseDetailState, courseDetailDispatch } = useCourseDetail()

  const { phoneNumber } = courseDetailState

  const navigate = useNavigate()

  var passingObject = {
    booker_phone: phoneNumber,
  };

  var encryptedTextToSend = convertToEncryption(passingObject)

  var bodyFormData = new FormData();

  bodyFormData.append("param1", encryptedTextToSend);

  const handleButtonClick = async () => {
    try {
      const { status, data }= await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/onSendBookingOTP`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if(status === 200 && data.success === "1"){
        courseDetailDispatch({ type: "SET_OTP_TOKEN", payload: { otpToken: data.token } })
        navigate("/login-otp")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className={cn(styles["login"])}>
        <div className={cn(styles["login__content"])}>
          <div className={cn(styles["login__content-left"])}>
            <h1 className={cn(styles["login__header"])}>
              Excel in your education <br />
              journey with <br />
              <span className={cn(styles["login__header-large"])}>
                LearnHat <span className={cn(styles["login__header-highlight"])}>LIVE</span> <br />
                Learning{" "}
              </span>
            </h1>
          </div>
          <div className={cn(styles["login__content-right"])}>
            <div className={cn(styles["login__input"])}>
              <h1>Login / Signup</h1>
              <InputText type="number" value={phoneNumber} placeholder="Mobile Number" onChange={(e) => courseDetailDispatch({ type: "SET_PHONE_NUMBER", payload: { phoneNumber: e.target.value } })} />
              <PurpleButton onClick={handleButtonClick}>Continue</PurpleButton>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(styles["login__get-applink"])}>
        <GetAppLink mt={"none"} />
      </div>
    </>
  );
};

export default LoginWithPhone;
