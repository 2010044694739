import { useLanguage } from "../../../language-content"
import cn from "classnames"

import styles from "./About.module.css"

const About = () => {
    const { languageData } = useLanguage()

    const { vision, mission, about } = languageData["mission_vision_about"]
    return (
      <section className={cn(styles["about"])}>
        <div className={cn(styles["about__section"])}>
          <p className={cn(styles["about__text"])}>
            <i className="color--brandyellow">{vision.title}: </i>
            {vision.message}
          </p>
          <p className={cn(styles["about__text"])}>
            <i className="color--brandyellow">{mission.title}: </i>
            {mission.message}
          </p>
        </div>
        <div className={cn(styles["about__section"], "mt-05")}>
          <p className={cn(styles["about__text"])}>
            <i className="color--brandyellow">{about.title}: </i>
            {about.section1.para1}
          </p>
          <p className={cn(styles["about__text"], "mt-105")}>
            {about.section1.para2}
          </p>
          <p className={cn(styles["about__text"], "mt-105")}>
            {about.section1.para3}
          </p>
        </div>
        <div className={cn(styles["about__section"], "mt-05")}>
          <p className={cn(styles["about__text"])}>
            {about.section2.para1}
          </p>
          <p className={cn(styles["about__text"], "mt-105")}>
            {about.section2.para2}
          </p>
        </div>
      </section>
    );
}

export default About
