import cn from "classnames";
import { useLanguage } from "../../language-content";
import styles from "./Hero.module.css";

const HeroSectionText = ({ type }) => {
  console.log(type);
  const { languageData } = useLanguage();
  const heroData = languageData[type];

  return (
    <article>
      {type === "spoken_english" ? (
        <h1 className={cn(styles.herosection__heading, "mt-1")}>
          <span className={cn(styles.herosection__highlight, "ml-0")}>
            {heroData["hero_main_text"].red}
          </span>{" "}
          {heroData["hero_main_text"].start}
        </h1>
      ) : (
        <h1 className={cn(styles.herosection__heading)}>
          {heroData["hero_main_text"].start}
          <span className={cn(styles.herosection__highlight)}>
            {heroData["hero_main_text"].red}
          </span>
          {heroData["hero_main_text"].end}
        </h1>
      )}
      <p className={cn(styles.herosection__description)}>
        {heroData["hero_description"]}
      </p>
    </article>
  );
};

export default HeroSectionText;
