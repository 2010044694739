import { useContext, createContext, useState } from "react"
import { languageContent } from "./data"

const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {

    const [currentLanguage, setCurrentLanguage] = useState("eng")
    const [languageData, setLanguageData] = useState(languageContent[currentLanguage])

    function handleLanguageSelection(lang){
        setCurrentLanguage(lang)
        setLanguageData(languageContent[lang])
        localStorage.setItem("learhat-lang", lang)
    }

    return (
        <LanguageContext.Provider value={{ currentLanguage, handleLanguageSelection, languageData }}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguage = () => useContext(LanguageContext)