import cn from "classnames"
import { useLanguage } from "../../../language-content"
import { MetricsCard } from "../../../utils/cards"

import styles from "./Metrics.module.css"

const Metrics = () => {

    const { languageData } = useLanguage()

    const { metricsData } = languageData["metrics"]

    return (
      <section className={cn(styles["metrics"])}>
        <div className={cn(styles["metrics__container"])}>
          <div className={cn(styles["metrics__display"])}>
            <div className={cn(styles["metrics__cards"])}>
              {metricsData.map(({ icon, number, message }) => {
                return (
                  <MetricsCard
                    key={number}
                    icon={icon}
                    number={number}
                    message={message}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
}

export default Metrics
