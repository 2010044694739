/* eslint-disable */
import { useState, useEffect } from "react";
import cn from "classnames";
import { InputSelect } from "../../utils/input";
import { BlueButton, PurpleButton } from "../../utils/buttons";
import { BestTeachers } from "../../home";
import axios from "axios";
import { CloudinaryImageHelper } from "../../utils/image-helper";
import { useCourseDetail } from "../courses";
import {
  validateBatch,
  validateBoard,
  validateGrade,
  validatePlan,
  validateSubject,
} from "../../utils/validation";
import { Link } from "react-router-dom"

import styles from "./CourseDetail.module.css";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const keyFeatures = {
  "1_to_5": [
    "20 LIVE Interactive Classes/month",
    "Batch size of up to 8 learners only",
    "Monthly Improvement Report for Parents",
    "School Exam Preparation and Revision Notes",
    "Focused on conceptual understanding of topics",
  ],
  "6_to_8": [
    "20 LIVE Interactive Classes/month",
    "Batch size of up to 8 learners only",
    "Monthly Improvement Report",
    "School Exam Preparation and Revision Notes",
    "Focused on building aptitude and critical reasoning",
    "Regular and Personalized Doubt-clearing sessions",
  ],
  "9_10": [
    "20 LIVE Interactive Classes/month",
    "Batch size of up to 8 learners only",
    "Monthly Improvement Report",
    "School Exam Preparation and Revision Notes",
    "Focused on practical knowledge and understanding",
    "Regular and Personalized Doubt-clearing sessions",
    "Live Interactive Doubt Clearing session",
  ],
  "11_12": [
    "Focused on building strong aptitude skills",
    "Specialized Exam Prep with Revision Notes",
    "Boost your % with interactive doubt clearing sessions",
    "Conceptual Clarity through Application-Based Learning",
    "Unique Pre Board Papers to make exam prep easy",
    "Proper mock subject papers for improvement",
  ],
};

const CourseDetail = () => {
  const [grades, setGrades] = useState([]);
  const [featureCategory, setFeatureCategory] = useState("");
  const [features, setFeatures] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [boards, setBoards] = useState([]);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [availableBatches, setAvailableBatches] = useState([]);
  const [gradeValidationMsg, setGradeValidationMsg] = useState("");
  const [subjectValidationMsg, setSubjectValidationMsg] = useState("");
  const [boardValidationMsg, setBoardValidationMsg] = useState("");
  const [batchValidationMsg, setBatchValidationMsg] = useState("");
  const [planValidationMsg, setPlanValidationMsg] = useState("");
  const [batchMessage, setBatchMessage] = useState("");
  const [getResultClicked, setGetResultClicked] = useState(false);

  const { courseDetailState, courseDetailDispatch } = useCourseDetail();

  const {
    selectedGrade,
    selectedSubject,
    selectedBoard,
    selectedBatch,
    selectedPlan,
  } = courseDetailState;

  useEffect(() => {
    getGradeList();
    getBoardsData();
  }, []);

  const getGradeList = async () => {
    try {
      const { status, data } = await axios.get(`${BACKEND_BASE_URL}/gradeList`);
      if (status === 200) {
        setGrades(data.grades.slice(1, 13));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubjectsData = async (grade) => {
    try {
      const { status, data } = await axios.get(
        `${BACKEND_BASE_URL}/getSubjects/${grade}`
      );
      if (status === 200) {
        setSubjects(data.subjects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBoardsData = async () => {
    try {
      const { status, data } = await axios.get(`${BACKEND_BASE_URL}/getBoards`);
      if (status === 200) {
        setBoards(data.boards);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFeatureCategory = (value) => {
    const grade = Number(value);
    if (grade >= 1 && grade <= 5) {
      setFeatureCategory("1_to_5");
    } else if (grade >= 6 && grade <= 8) {
      setFeatureCategory("6_to_8");
    } else if (grade >= 9 && grade <= 10) {
      setFeatureCategory("9_10");
    } else {
      setFeatureCategory("11_12");
    }
  };

  const getPlanLists = async () => {
    try {
      const { status, data } = await axios.get(
        `${BACKEND_BASE_URL}/getPlanLists/${selectedGrade[0]}/${selectedSubject[0]}/LearnHat/${selectedBoard[0]}`
      );
      if (status === 200) {
        setAvailablePlans(data["plan_data"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBatchLists = async () => {
    try {
      const { status, data } = await axios.get(
        `${BACKEND_BASE_URL}/getBatchDetails/${selectedGrade[0]}/${selectedSubject[0]}/${selectedBoard[0]}/LearnHat`
      );
      if (status === 200) {
        setAvailableBatches(data["own_batch_lists"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGradeSelection = (e) => {
    courseDetailDispatch({
      type: "SET_SELECTED_GRADE",
      payload: { selectedGrade: e.target.value.split(",") },
    });
    getSubjectsData(e.target.value.split(",")[1]);
    getFeatureCategory(e.target.value.split(",")[1]);
  };

  const handleSubjectSelection = (e) => {
    courseDetailDispatch({
      type: "SET_SELECTED_SUBJECT",
      payload: { selectedSubject: e.target.value.split(",") },
    });
  };

  const handleBoardSelection = (e) => {
    courseDetailDispatch({
      type: "SET_SELECTED_BOARD",
      payload: { selectedBoard: e.target.value.split(",") },
    });
  };

  const handleGetDetailsClick = () => {
    setGetResultClicked(true)
    setGradeValidationMsg(validateGrade(selectedGrade[0]));
    setSubjectValidationMsg(validateSubject(selectedSubject[0]));
    setBoardValidationMsg(validateBoard(selectedBoard[0]));
    if (
      gradeValidationMsg === "" &&
      subjectValidationMsg === "" &&
      boardValidationMsg === ""
    ) {
      setFeatures(() => keyFeatures[featureCategory]);
      getPlanLists();
      getBatchLists();
      availableBatches.length > 0 ? setBatchMessage("") : setBatchMessage("Sorry no batch available for now.")
    }
  };

  useEffect(() => {
     courseDetailDispatch({ type: "CLEAR_CHOICES" });
     setGetResultClicked(false);
     setFeatures([]);
     setAvailableBatches([]);
     setAvailablePlans([]);
     setBatchMessage("");
  }, [])

  return (
    <div className={cn(styles["coursedetail"])}>
      <div className={cn(styles["coursedetail-input"])}>
        <InputSelect
          onChange={(e) => handleGradeSelection(e)}
          disabled={selectedGrade.length > 0 && true}
        >
          <option value="" selected={!getResultClicked} hidden>
            Select Grade
          </option>
          {grades.map(({ class_id, class_name }) => {
            return (
              <option key={class_id} value={[class_id, class_name]}>
                {class_name}
              </option>
            );
          })}
        </InputSelect>
        {selectedGrade.length > 0 && (
          <InputSelect
            onChange={handleSubjectSelection}
            disabled={selectedSubject.length > 0 && true}
          >
            <option value="" selected={!getResultClicked} hidden>
              Select Subject
            </option>
            {subjects.map(({ subject_id, subject_name }) => (
              <option key={subject_id} value={[subject_id, subject_name]}>
                {subject_name}
              </option>
            ))}
          </InputSelect>
        )}
        <InputSelect
          onChange={handleBoardSelection}
          disabled={selectedBoard.length > 0 && true}
        >
          <option value="" selected={!getResultClicked} hidden>
            Select Board
          </option>
          {boards.map(({ board_id, board_name }) => (
            <option key={board_id} value={[board_id, board_name]}>
              {board_name}
            </option>
          ))}
        </InputSelect>
        <PurpleButton
          btnType="btn--large"
          onClick={() => handleGetDetailsClick()}
        >
          Get Details
        </PurpleButton>
        <BlueButton
          onClick={() => {
            courseDetailDispatch({ type: "CLEAR_CHOICES" });
            setGetResultClicked(false);
            setFeatures([]);
            setAvailableBatches([]);
            setAvailablePlans([]);
            setBatchMessage("");
          }}
        >
          Clear Choice
        </BlueButton>
      </div>
      {<div className="center mt-1 mb-1"></div>}
      {features.length > 0 && (
        <div className={styles["coursedetail__features"]}>
          <h1 className={cn(styles["coursedetail__feature-heading"])}>
            Grade {selectedGrade[1]}{" "}
            <span className="color--yellow">{selectedSubject[1]}</span> For{" "}
            <span className="color--yellow">{selectedBoard[1]} Board</span>
          </h1>
          <div>
            <h2 className={cn(styles["coursedetail__badges"])}>
              Includes:
              <span className={cn("pill-badge", "bgcolor--red")}>
                Live Classes
              </span>
              <span className={cn("pill-badge", "bgcolor--yellow")}>
                Video Content
              </span>
              <span className={cn("pill-badge", "bgcolor--lightblue")}>
                Doubt Clearing
              </span>
            </h2>
            <div className={cn(styles["coursedetail__keyfeatures"])}>
              <h2>Features you'll get</h2>
              {features.length > 0 &&
                features.map((feature) => {
                  return (
                    <div key={feature}>
                      <CloudinaryImageHelper
                        publicId={"/icons/course-details-head-arrow"}
                        alt="course-details-head-arrow"
                      />
                      <p>{feature}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {
        <div className={cn(styles["coursedetails__batches"])}>
          {availableBatches.length > 0 ? (
            availableBatches.map(
              ({
                slot_id,
                batch_start_date,
                batch_end_date,
                seats_available,
                slot_timings,
              }) => {
                return (
                  <div
                    key={slot_id}
                    className={cn(
                      styles["coursedetail__batch"],
                      slot_id === selectedBatch && styles["detailscard--active"]
                    )}
                  >
                    <div className={cn(styles["coursedetail__batchdetail"])}>
                      <h1>Start Date</h1>
                      <div>
                        <p>{batch_start_date}</p>
                      </div>
                    </div>
                    <div className={cn(styles["coursedetail__batchdetail"])}>
                      <h1>End Date</h1>
                      <div>
                        <p>{batch_end_date}</p>
                      </div>
                    </div>
                    <div className={cn(styles["coursedetail__batchdetail"])}>
                      <h1>Schedule</h1>
                      <div>
                        <div>
                          {slot_timings.map(
                            ({ slot_time_start, slot_time_end, week_day }) => {
                              return (
                                <p>
                                  {week_day}: {slot_time_start} To{" "}
                                  {slot_time_end}
                                </p>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles["coursedetail__batchdetail"])}>
                      <h1>Availability</h1>
                      <div>
                        <span
                          className={cn(
                            "pill-badge",
                            seats_available > 0
                              ? "bgcolor--green"
                              : "bgcolor--red"
                          )}
                        >
                          {seats_available > 0 ? "Available" : "Booked"}
                        </span>
                      </div>
                    </div>
                    <div className={cn(styles["coursedetail__batchdetail"])}>
                      <h1>Action</h1>
                      <div>
                        <PurpleButton
                          onClick={() =>
                            courseDetailDispatch({
                              type: "SET_SELECTED_BATCH",
                              payload: { selectedBatch: slot_id },
                            })
                          }
                          disabled={seats_available === 0}
                        >
                          {slot_id === selectedBatch ? "Selected" : "Select"}
                        </PurpleButton>
                      </div>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <h1 className="center">{batchMessage}</h1>
          )}
        </div>
      }
      {selectedBatch !== "" && (
        <div className={styles["coursedetail__plans"]}>
          {availablePlans.length > 0 &&
            availablePlans.map(
              ({
                subject_plan_id,
                subject_price,
                discount_percentage,
                subject_price_duration,
                subject_price_duration_type_value,
                amount_after_discount,
                discounted_price,
                discount_amount,
                per_month_amount,
                per_class_amount,
              }) => {
                return (
                  <div
                    key={subject_plan_id}
                    className={cn(
                      styles["coursedetail__plancard"],
                      selectedPlan.subject_plan_id === subject_plan_id &&
                        styles["planscard--active"]
                    )}
                  >
                    {subject_price_duration === "3" &&
                      subject_price_duration_type_value === "Months" && (
                        <h1>Quarterly Plan</h1>
                      )}
                    {subject_price_duration === "6" &&
                      subject_price_duration_type_value === "Months" && (
                        <h1>Half-Yearly Plan</h1>
                      )}
                    {subject_price_duration === "1" &&
                      subject_price_duration_type_value === "Year" && (
                        <h1>Annual Plan</h1>
                      )}
                    {subject_price_duration === "2" &&
                      subject_price_duration_type_value === "Years" && (
                        <h1>2 years Plan</h1>
                      )}
                    <h2>₹ {subject_price}</h2>
                    <p>
                      {" "}
                      PER CLASS: <b>₹ {Math.floor(per_class_amount)}</b>
                    </p>
                    <p>
                      {" "}
                      PER MONTH: <b>₹ {Math.floor(per_month_amount)}</b>
                    </p>
                    <PurpleButton
                      onClick={() =>
                        courseDetailDispatch({
                          type: "SET_SELECTED_PLAN",
                          payload: {
                            selectedPlan: {
                              subject_plan_id,
                              subject_price,
                              subject_price_duration,
                              subject_price_duration_type_value,
                            },
                          },
                        })
                      }
                    >
                      {selectedPlan.subject_plan_id === subject_plan_id
                        ? "Selected"
                        : "Select plan"}
                    </PurpleButton>
                  </div>
                );
              }
            )}
        </div>
      )}
      {selectedPlan.subject_plan_id !== "" && availableBatches.length > 0 && (
        <Link to="/login-phone">
          <div className={cn(styles["coursedetail__cta"])}>
            <PurpleButton btnType={"btn--large"}>Buy Now</PurpleButton>
          </div>
        </Link>
      )}
      <BestTeachers />
    </div>
  );
};

export default CourseDetail;
