import { useEffect } from "react";
import { Header } from "./components/header";
import { Home } from "./components/home";
import { AboutUs } from "./components/home";
import { Links } from "./components/links";
import { Footer } from "./components/footer";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

import { CoursesK12, SpokenEnglish, TeachWithUs ,Terms, Privacy } from "./components/pages";
import { CourseDetail, CoursesAdultLearner } from "./components/pages/courses";
import { EnterOTP, LoginWithPhone, PaymentDetails } from "./components/pages/payment";

const App = () => {

  useEffect(() => {
    ReactGA.initialize('UA-201454320-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])

  const { pathname } = useLocation();

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  
  return (
    <div className="App">
      <Header pathname={pathname} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coursesk12" element={<CoursesK12 />} />
        <Route path="/courses-adult-learners" element={<CoursesAdultLearner /> } />
        <Route path="/course-detail" element={<CourseDetail />} />
        <Route path="/login-phone" element={<LoginWithPhone />} />
        <Route path="/login-otp" element={<EnterOTP />} />
        <Route path="/spoken-english" element={<SpokenEnglish />} />
        <Route path="/teach-with-us" element={<TeachWithUs />} />
        <Route path="/buy-course" element={<PaymentDetails />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Links />
      {pathname === "/" && <AboutUs />}
      <Footer />
    </div>
  );
};

export default App;
