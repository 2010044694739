/* eslint-disable */ 
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlueButton, IconButton, IconWithTextButton } from "../utils/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faTimes,
  faPhoneAlt,
  faEnvelope,
  faTwitter,
  faFacebook,
  faInstagram,
} from "../utils/icons";
import cn from "classnames";
import { useLanguage } from "../language-content";
import { CloudinaryImageHelper } from "../utils/image-helper";
import { useLocation } from "react-router-dom"

import styles from "./MobileNavbar.module.css";

const MobileNavbar = ({ mobileMenuToggle, handleMenuToggle }) => {
  const [courseSubmenuDisplay, setCourseSubmenuDisplay] = useState(false);
  const [moreSubmenuDisplay, setMoreSubmenuDisplay] = useState(false);
  const [k12Submenu, setK12Submenu] = useState(false);
  const [adLearnerSubmenu, setAdLearnerSubmenu] = useState(false);

  const { languageData, currentLanguage } = useLanguage();
  const navData = languageData.nav;

  const { pathname } = useLocation()

  function handleCourseSubmenu() {
    setCourseSubmenuDisplay(!courseSubmenuDisplay);
  }

  function handleMoreSubmenu() {
    setMoreSubmenuDisplay(!moreSubmenuDisplay);
  }

  function handleK12Submenu() {
    setK12Submenu(!k12Submenu);
  }

  function handleAdLearnerSubmenu() {
    setAdLearnerSubmenu(!adLearnerSubmenu);
  }

  function handleSelectChange(e) {
    // handleLanguageSelection(e.target.value)
  }

  function handleReset() {
    setAdLearnerSubmenu(false);
    setCourseSubmenuDisplay(false);
    setK12Submenu(false);
  }

  useEffect(() => {
    return () => {
      handleReset();
    };
  }, [pathname]);

  useEffect(() => {
    mobileMenuToggle && handleMenuToggle();
  }, [])

  return (
    <div className={cn(styles["navbar"], mobileMenuToggle && styles["active"])}>
      <div className={cn(styles["navbar__brand"])}>
        <Link to="/" onClick={handleMenuToggle}>
          <CloudinaryImageHelper
            publicId="logo_learnhat"
            alt="learnhat_logo"
            width="180"
            height="50"
          />
        </Link>
        <IconButton onClick={handleMenuToggle}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </div>
      <div className={cn(styles["navbar__links"])}>
        <IconWithTextButton
          onClick={() => setCourseSubmenuDisplay(!courseSubmenuDisplay)}
          className={cn(styles["navbar__dropdown-menuitem"])}
        >
          {navData["links"][0].text}
          <FontAwesomeIcon icon={faChevronDown} className={"ml-1"} />
        </IconWithTextButton>
        <CoursesSubmenu
          courseSubmenuDisplay={courseSubmenuDisplay}
          handleCourseSubmenu={handleCourseSubmenu}
          handleK12Submenu={handleK12Submenu}
          k12Submenu={k12Submenu}
          adLearnerSubmenu={adLearnerSubmenu}
          handleAdLearnerSubmenu={handleAdLearnerSubmenu}
          subMenuData={navData["links"][0].submenu}
          setK12Submenu={setK12Submenu}
          setAdLearnerSubmenu={setAdLearnerSubmenu}
          handleMenuToggle={handleMenuToggle}
        />
        {navData.links.map((navItem) => {
          return navItem.path ? (
            <Link
              key={navItem.text}
              to={navItem.path}
              className={cn(styles["navbar__linkitems"])}
              onClick={handleMenuToggle}
            >
              {navItem.text}
            </Link>
          ) : (
            navItem.type === "newWindow" && (
              <a
                key={navItem.text}
                href={navItem.to}
                target="_blank"
                rel="noreferrer"
                className={cn(styles["navbar__linkitems"])}
              >
                {navItem.text}
              </a>
            )
          );
        })}
        {/* <IconWithTextButton
          onClick={handleMoreSubmenu}
          className={cn(styles["navbar__dropdown-menuitem"])}
        >
          {navData["links"][3].text}
          <FontAwesomeIcon icon={faChevronDown} className={"ml-1"} />
        </IconWithTextButton>
        <MoreSubmenu
          moreSubmenuDisplay={moreSubmenuDisplay}
          handleMoreSubmenu={handleMoreSubmenu}
          subMenuData={navData["links"][3].submenu}
          handleMenuToggle={handleMenuToggle}
        /> */}
        <select
          onChange={handleSelectChange}
          className={cn(styles["navbar__lang"])}
        >
          {["ENG", "HIN"].map((lang) => {
            return (
              <option
                key={lang}
                value={lang}
                selected={currentLanguage === lang}
              >
                {lang}
              </option>
            );
          })}
        </select>
        <Link to="/#hero">
          <BlueButton>{navData["nav_cta_1"]}</BlueButton>
        </Link>
        <a
          href="https://lms.learnhat.com/"
          target="_blank"
          rel="noreferrer"
          className="mt-05"
        >
          <BlueButton>{navData["nav_cta_2"]}</BlueButton>
        </a>
      </div>
      <div className={cn(styles["navbar__contact-info"])}>
        <FontAwesomeIcon
          icon={faEnvelope}
          className={cn(styles["contact-icon"])}
        />
        <a href="mailto:info@templatepath.com">info@learnhat.com</a>
      </div>
      <div className={cn(styles["navbar__contact-info"], "mr-1")}>
        <FontAwesomeIcon
          icon={faPhoneAlt}
          className={cn(styles["contact-icon"])}
        />
        <a href="tel:1800 833 5077">1800 833 5077</a>
      </div>
      <article className={cn(styles["navbar__social"])}>
        <a href="https://twitter.com/LearnHat" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://www.facebook.com/learnhat"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://www.instagram.com/learn_hat_/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </article>
    </div>
  );
};

const CoursesSubmenu = ({
  courseSubmenuDisplay,
  subMenuData,
  k12Submenu,
  handleK12Submenu,
  adLearnerSubmenu,
  handleAdLearnerSubmenu,
  setK12Submenu,
  setAdLearnerSubmenu,
  handleMenuToggle
}) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        courseSubmenuDisplay && styles.submenuactive
      )}
    >
      <IconWithTextButton onClick={() => setK12Submenu(!k12Submenu)}>
        {subMenuData[0].text}
        <FontAwesomeIcon icon={faChevronDown} className={"ml-1"} />
      </IconWithTextButton>
      <K12Submenu
        k12Submenu={k12Submenu}
        handleK12Submenu={handleK12Submenu}
        subMenuData={subMenuData[0].submenu}
        handleMenuToggle={handleMenuToggle}
      />
      <IconWithTextButton
        onClick={() => setAdLearnerSubmenu(!adLearnerSubmenu)}
      >
        {subMenuData[1].text}
        <FontAwesomeIcon icon={faChevronDown} className={"ml-1"} />
      </IconWithTextButton>
      <AdLearnerSubmenu
        adLearnerSubmenu={adLearnerSubmenu}
        handleAdLearnerSubmenu={handleAdLearnerSubmenu}
        subMenuData={subMenuData[1].submenu}
        handleMenuToggle={handleMenuToggle}
      />
    </div>
  );
};

const K12Submenu = ({ k12Submenu, subMenuData, handleMenuToggle }) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        styles["navbar__submenu--lvl2"],
        k12Submenu && styles.submenuactive
      )}
    >
      {subMenuData.map((subMenuItem) => {
        return (
          <Link
            key={subMenuItem.text}
            to={subMenuItem.path}
            className={cn(
              styles["navbar__submenu-item"],
              styles["navbar__submenu-item--lvl2"]
            )}
            onClick={handleMenuToggle}
          >
            {subMenuItem.text}
          </Link>
        );
      })}
    </div>
  );
};

const AdLearnerSubmenu = ({
  adLearnerSubmenu,
  subMenuData,
  handleMenuToggle
}) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        styles["navbar__submenu--lvl2"],
        styles["navbar__submenu--lvl2--1item"],
        adLearnerSubmenu && styles.submenuactive
      )}
    >
      {subMenuData.map((subMenuItem) => {
        return (
          <Link
            key={subMenuItem.text}
            to={subMenuItem.path}
            className={cn(
              styles["navbar__submenu-item"],
              styles["navbar__submenu-item--lvl2"]
            )}
            onClick={handleMenuToggle}
          >
            {subMenuItem.text}
          </Link>
        );
      })}
    </div>
  );
};

const MoreSubmenu = ({
  moreSubmenuDisplay,
  handleMoreSubmenu,
  subMenuData,
  handleMenuToggle,
}) => {
  return (
    <div
      className={cn(
        styles["navbar__submenu"],
        moreSubmenuDisplay && styles.submenuactive
      )}
      onMouseLeave={handleMoreSubmenu}
    >
      {subMenuData.map((subMenuItem) => {
        return (
          <Link
            key={subMenuItem.text}
            to={subMenuItem.path}
            className={cn(styles["navbar__submenu-item"])}
            onClick={handleMenuToggle}
          >
            {subMenuItem.text}
          </Link>
        );
      })}
    </div>
  );
};

export default MobileNavbar;
