import { useLanguage } from "../../language-content"
import cn from "classnames"

import styles from "./Terms.module.css"

const Terms = () => {

    const { languageData } = useLanguage()

    const { heading, subheading, introduction, terms } = languageData["terms_and_conditions"]

    return (
      <div className={cn(styles["terms"])}>
        <h1 className={cn(styles["terms__heading"])}>{heading}</h1>
        <p>{subheading}</p>
        <p>{introduction}</p>
        {
            terms.map(term => {
                return (
                  <div>
                    <h2 className={cn(styles["terms__subheading"])}>
                      {term.title}
                    </h2>
                    {term.description &&
                      term.description.map((des) => {
                        return typeof des === "string" ? (
                          <p>{des}</p>
                        ) : (
                          <>
                            <p>{des.text}</p>
                            <ol>
                              {des.subpoints.map((subpoint) => {
                                return typeof subpoint === "string" ? (
                                  <>
                                    <li>{subpoint}</li>
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      {subpoint.text}
                                      <ol>
                                        {subpoint.subpoints.map(
                                          (innerSubpoint) => (
                                            <li>{innerSubpoint}</li>
                                          )
                                        )}
                                      </ol>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </>
                        );
                      })}
                    {term.points && term.points.length > 0 && (
                      <ol>
                        {term.points.map((point) => {
                          return typeof point === "string" ? (
                            <li>{point}</li>
                          ) : (
                            <>
                              <li>{point.text}</li>
                              <ol>
                                {point.subpoints.map((subpoint) => {
                                  return <li>{subpoint}</li>;
                                })}
                              </ol>
                            </>
                          );
                        })}
                      </ol>
                    )}
                  </div>
                );
            })
        }
      </div>
    );
}

export default Terms
