import cn from "classnames"
import styles from "./AboutAndWhy.module.css"
import Sections from "./Sections"
import WhyLearnhat from "./WhyLearnhat"
import { Metrics } from "../MetricsAndTestimonials"

const AboutAndWhy = () => {
    return (
      <section className={cn(styles.aboutandwhy)}>
        <WhyLearnhat />
        <Metrics />
        <Sections />
      </section>
    );
}

export default AboutAndWhy
