import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"
import { CloudinaryContext } from "cloudinary-react"
import { LanguageProvider } from "./components/language-content"
import { CourseDetailProvider } from "./components/pages/courses"

import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CloudinaryContext cloudName="learnhat" secure="true">
        <LanguageProvider>
          <CourseDetailProvider>
            <App />
          </CourseDetailProvider>
        </LanguageProvider>
      </CloudinaryContext>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
