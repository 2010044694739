/* eslint-disable */
import { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Courses.module.css";
import { useLanguage } from "../../language-content";
import { CloudinaryImageHelper } from "../../utils/image-helper";
import { PurpleButton } from "../../utils/buttons";
import { Link } from "react-router-dom";
import { gaButtonClickHandler } from "../../utils/google-analytics";

const CoursesK12 = () => {
  const [gradeCategory, setGradeCategory] = useState("1-5");
  const gradeCategories = ["1-5", "6-8", "9-10", "11-12"];
  const [subjectsData, setSubjectsData] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("")

  const { languageData } = useLanguage();

  const { subjects, languages, boards, cta, features } =
    languageData["courses_k12"];

  function handleGradeCategoryClick(category) {
    setGradeCategory(category);
    setSubjectsData(() => subjects[category]["subjects"]);
    setCurrentMessage(() => subjects[category]["message"]);
  }

  useEffect(() => {
    setSubjectsData(() => subjects[gradeCategory]["subjects"]);
    setCurrentMessage(() => subjects[gradeCategory]["message"]);
  }, []);

  return (
    <div className={cn(styles["courses"])}>
      {features.length > 0 && (
        <div className={styles["courses__features"]}>
          <h2 className={cn(styles["courses__badges"])}>
            <span className={cn("pill-badge", "bgcolor--red")}>
              Live Classes
            </span>
            <span className={cn("pill-badge", "bgcolor--yellow")}>
              Video Content
            </span>
            <span className={cn("pill-badge", "bgcolor--lightblue")}>
              Doubt Clearing
            </span>
          </h2>
          <div>
            <div className={cn(styles["courses__keyfeatures"])}>
              <h2>Key Features:</h2>
              {features.length > 0 &&
                features.map((feature) => {
                  return (
                    <div key={feature}>
                      <CloudinaryImageHelper
                        publicId={"/icons/course-details-head-arrow"}
                        alt="course-details-head-arrow"
                      />
                      <p>{feature}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <div className={cn(styles["courses__content"])}>
        <div className={cn(styles["courses__gradecontainer"])}>
          {gradeCategories.map((category) => {
            return (
              <button
                key={category}
                className={cn(
                  styles["courses__gradecard"],
                  category === gradeCategory &&
                    styles["courses__gradecard--active"]
                )}
                onClick={() => handleGradeCategoryClick(category)}
              >
                <p>Class {category}</p>
              </button>
            );
          })}
        </div>
        {subjectsData.length > 0 && (
          <div>
            <h2 className={cn(styles["courses__subject-message"])}>
              {currentMessage}
            </h2>
            <div>
              <div className={cn(styles["courses__detail-heading"])}>
                <div></div>
                <h3>{subjects["title"]}</h3>
                <div></div>
              </div>
              <div className={cn(styles["courses__subjects-container"])}>
                {subjectsData.map((subject) => {
                  return (
                    <div className={cn(styles["courses__subject-card"])}>
                      <CloudinaryImageHelper
                        publicId={`icons/${subject.icon}`}
                      />
                      <p>{subject.name}</p>
                      <p className="">
                        {subject.starting}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className={cn(styles["courses__boards-container"])}>
                <div className={cn(styles["courses__detail-heading"])}>
                  <div></div>
                  <h3>{boards["title"]}</h3>
                  <div></div>
                </div>
                <ul>
                  {boards.list.map((board) => {
                    return (
                      <li
                        className={cn(styles["courses__board-list"])}
                        key={board}
                      >
                        {board}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={cn(styles["courses__language-container"])}>
                <div
                  className={cn(
                    styles["courses__detail-heading"],
                    styles["courses__detail-heading--language"]
                  )}
                >
                  <div></div>
                  <h3>{languages["title"]}</h3>
                  <div></div>
                </div>
                <div>
                  {languages.list.slice(0, -1).map((board) => {
                    return (
                      <span key={board}>
                        {board}
                      </span>
                    );
                  })}
                </div>
                <p className="center mt-1">
                  {languages.list[languages.list.length - 1]}
                </p>
              </div>
              <div
                className={cn(
                  styles["courses__cta-container"],
                  "flex column al-center"
                )}
              >
                <Link to="/#hero" className="mt-1">
                  <PurpleButton
                  onClick={() => gaButtonClickHandler('Button',`Free Trial class ${gradeCategory}`, window.location.pathname)}
                  >{cta}</PurpleButton>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesK12;
