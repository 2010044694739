export const languageContent = {
  eng: {
    nav: {
      links: [
        {
          text: "Courses",
          submenu: [
            {
              text: "For Class 1-12",
              submenu: [
                {
                  text: "Curriculum",
                  path: "/coursesk12",
                },
                {
                  text: "Spoken English",
                  path: "/spoken-english",
                },
              ],
            },
            {
              text: "For Adult Learners",
              submenu: [
                {
                  text: "Spoken English",
                  path: "/spoken-english",
                },
                {
                  text: "Yoga",
                  path: "/courses-adult-learners",
                },
                {
                  text: "Abacus",
                  path: "/courses-adult-learners",
                },
              ],
            },
          ],
        },
        {
          type: "newWindow",
          text: "LANCET",
          to: "https://lancet.learnhat.com/",
        },
        {
          text: "Teach With Us",
          path: "/teach-with-us",
        },
        // {
        //   text: "More",
        //   submenu: [
        //     {
        //       text: "About Us",
        //       path: "/",
        //     },
        //     {
        //       text: "News & Blog",
        //       path: "/",
        //     },
        //     {
        //       text: "Connect With Us",
        //       path: "/",
        //     },
        //   ],
        // },
      ],
      nav_cta_1: "Free Trial Class",
      nav_cta_2: "Sign In",
    },
    hero: {
      hero_main_text: {
        start: "Excel In Your Education Journey With LearnHat",
        red: "LIVE",
        end: "Learning!",
      },
      hero_description: "Home tuitions in 9+ languages of your choice",
    },
    get_app_link: {
      get_app_link_heading: "Get App Download Link via SMS",
      get_app_link_input_placeholder: "Mobile No.",
      get_app_link_cta: "Get link",
    },
    about_learnhat: {
      section_cards: [
        {
          path: "/coursesk12",
          image: "kids_section",
          card_heading: "For Class 1 – 12",
          card_subheading: {
            top: "ICSE, CBSE, State Boards",
            bottom: "All Major Subjects",
          },
        },
        {
          path: "/courses-adult-learners",
          image: "adultlearners_section",
          card_heading: "For Adult Learners",
          card_subheading: {
            top: "All Major Subjects &",
            bottom: "Examination",
          },
        },
      ],
    },
    why_learnhat: {
      why_learnhat_heading: "Why Learn With LearnHat?",
      cards: [
        {
          icon: "interactive",
          heading: {
            top_start: "100%",
            highlight_text: "LIVE",
            top_end: "",
            bottom: "",
          },
          subheading: {
            top_start: "Interactive Classes with",
            highlight_text: "",
            top_end: "",
            bottom: "Best Teachers",
          },
        },
        {
          icon: "study",
          heading: {
            top_start: "Study, Revise,",
            highlight_text: "",
            top_end: "",
            bottom: "Doubt-clearing",
          },
          subheading: {
            top_start: "All Bundled",
            highlight_text: "TOGETHER",
            top_end: "",
            bottom: "",
          },
        },
        {
          icon: "handraise",
          heading: {
            top_start: "Learn in your",
            highlight_text: "OWN",
            top_end: "",
            bottom: "Language",
          },
          subheading: {
            top_start: "(Medium of Instruction)",
            highlight_text: "",
            top_end: "",
            bottom: "",
          },
        },
        {
          icon: "all-ok",
          heading: {
            top_start: "",
            highlight_text: "SMALL",
            top_end: "Batch Size",
            bottom: "Guaranteed",
          },
          subheading: {
            top_start: "(1 Teacher : 6 Students)",
            highlight_text: "",
            top_end: "",
            bottom: "",
          },
        },
        {
          icon: "professor-1",
          heading: {
            top_start: "100%",
            highlight_text: "ATTENTION",
            top_end: "from",
            bottom: "Teacher",
          },
          subheading: {
            top_start: "Monthly PTM",
            highlight_text: "",
            top_end: "",
            bottom: "",
          },
        },
        {
          icon: "idea-bulb",
          heading: {
            top_start: "Works like home",
            highlight_text: "",
            top_end: "",
            bottom: "tuitions",
          },
          subheading: {
            top_start: "Much",
            highlight_text: "SAFER, BETTER!",
            top_end: "",
            bottom: "",
          },
        },
      ],
    },
    best_teachers: {
      heading: "You Learn From INDIA’s Best Teachers",
      teachers: [
        {
          imgUrl: "Ritashree",
          name: "Ritashree  Ma'am",
          subjects: "English",
          experience: "23 years experience",
          education: "M.A.",
        },
        {
          imgUrl: "Swathi_Saluva",
          name: "Swathi Ma'am",
          subjects: "Maths",
          experience: "7 years experience",
          education: "M.Sc.",
        },
        {
          imgUrl: "Suparna_Dey",
          name: "Suparna Ma'am",
          subjects: "All Subjects",
          experience: "6 years experience",
          education: "B.Com",
        },
        {
          imgUrl: "Sania_Khan",
          name: "Sania Ma'am",
          subjects: "Maths & Science",
          experience: "4 years experience",
          education: "B.E.",
        },
      ],
      cta1: "See Top Teachers",
      cta2: "Take Free Trial Class",
    },
    metrics: {
      metricsData: [
        {
          number: "5,900",
          message: {
            top: "Happy Students &",
            middle: "Parents (in just few",
            bottom: "months)",
          },
        },
        {
          number: "600",
          message: {
            top: "Teachers",
            middle: "(experienced & highly",
            bottom: "qualified)",
          },
        },
        {
          number: "9,000",
          message: {
            top: "Classes",
            middle: "Done",
            bottom: "",
          },
        },
        {
          number: "35,00,000",
          message: {
            top: "Minutes",
            middle: "of Learning",
            bottom: "",
          },
        },
      ],
    },
    student_testimonials: {
      heading: "Our Students & Parents Love Us!",
      testimonialsData: [
        {
          givenBy: {
            name: "Medhansh Narayan",
          },
          videoUrl: "https://youtu.be/C1rWyRO6opc",
        },
        {
          givenBy: {
            name: "Sreeje Das",
          },
          videoUrl: "https://youtu.be/qw9Vlt5JPCE",
        },
        {
          givenBy: {
            name: "Alex R Chakraborty",
          },
          videoUrl: "https://youtu.be/VLkOQDpFyNg",
        },
      ],
    },
    links_and_contact: {
      get_app_link: {
        title: "Get the LearnHat App & start learning",
        input_placeholder: "Your Mobile No.",
        cta: "Get Link",
      },
      contact_us: {
        title: "Contact Us",
      },
      quick_links: {
        title: "Quick Links",
        subtitle: "Courses",
        links: {
          set1: [
            {
              text: "For Class 1 – 5",
              path: "/",
            },
            {
              text: "For Class 6 – 8",
              path: "/",
            },
            {
              text: "For Class 9 – 10",
              path: "/",
            },
            {
              text: "For Class 11 – 12",
              path: "/",
            },
            {
              text: "For Adults",
              path: "/",
            },
          ],
          set2: [
            {
              text: "For CBSE",
              path: "/",
            },
            {
              text: "For ICSE",
              path: "/",
            },
            {
              text: "For Other Boards",
              path: "/",
            },
          ],
          set3: [
            {
              text: "All Subjects",
              path: "/",
            },
            {
              text: "Science",
              path: "/",
            },
            {
              text: "Math",
              path: "/",
            },
            {
              text: "English",
              path: "/",
            },
            {
              text: "Spoken English",
              path: "/",
            },
          ],
        },
      },
      scholarships: {
        title: "Scholarships",
        links: [
          {
            text: "For Class 1 – 10",
            path: "/",
          },
          {
            text: "For Class 11 – 12 Science",
            path: "/",
          },
          {
            text: "For Class 11 – 12 Commerce",
            path: "/",
          },
        ],
      },
      library: {
        title: "Library",
        links: [
          {
            text: "Free Resources",
            path: "/",
          },
          {
            text: "CBSE",
            path: "/",
          },
          {
            text: "ICSE",
            path: "/",
          },
          {
            text: "WB Board",
            path: "/",
          },
          {
            text: "Bihar Board",
            path: "/",
          },
          {
            text: "Telangana Board",
            path: "/",
          },
          {
            text: "UP Board",
            path: "/",
          },
        ],
      },
      more_links1: [
        {
          text: "About Us",
          path: "/",
        },
        {
          text: "LIVE Classes",
          path: "/",
        },
        {
          text: "Benefits",
          path: "/",
        },
        {
          text: "Teach with Us",
          path: "/teach-with-us",
        },
        {
          text: "Our Tutors",
          path: "/",
        },
        {
          text: "LearnHat Blog",
          to: "https://blog.learnhat.com/",
        },
      ],
      more_links2: [
        {
          text: "Hear from our CEO",
          path: "/",
        },
        {
          text: "Testimonials & Stories",
          path: "/",
        },
        {
          text: "LearnHat in Media",
          path: "/",
        },
        {
          text: "We are HIRING!",
          path: "/",
        },
      ],
    },
    mission_vision_about: {
      vision: {
        title: "LearnHat’s Vision",
        message:
          "Provide learners across India and the world with best-in-class LIVE education that helps them expand their aspirations and grow in life",
      },
      mission: {
        title: "LearnHat’s Mission",
        message:
          "Provide learners and students with LIVE learning opportunities in the language (vernacular) of their choice, at affordable costs in order to fulfill their learning ambitions",
      },
      about: {
        title: "About Us",
        section1: {
          para1:
            "LearnHat is a LIVE, interactive learning platform connecting learners & students with high calibre tutors from across India. LearnHat makes it easy and affordable for any student in any part of India to connect with and learn from tutors of their choice for their curricular learning needs as well as co-curricular lessons. We are all about democratizing learning and are mission-driven by the fact that every learner must get the best education and have the right tools to build a solid future.",
          para2:
            "Our platform (on both website and Android app) currently provides live tutoring to Grades 1 – 12 students (across multiple subjects and in vernacular languages) and Spoken English learners (both children and adults).",
          para3:
            "LearnHat is a product of Orion Softech & Contentgrill Pvt. Ltd and is headquartered in Bangalore with a quick growing team across Product, Engineering, Sales, Marketing, Academics, Design and Operations. Priyadeep Sinha, our CEO, is a serial edtech entrepreneur and comes with over a decade experience in education and entrepreneurship.",
        },
        section2: {
          para1:
            "Orion Edutech (our parent company) is an NSDC partnered and ISO 9001:2008 certified company, founded by Sanjeev Kothari and Manish Agarwal who have built this into one of the largest skilling and placement companies in India. Founded in 2006, the company has focused on bridging the gap between the demand and supply of skilled workforce in the industry, and has successfully trained and placed more than 300,000 job aspirants in various top MNCs and corporate houses.",
          para2:
            "Quality education is a right for all students and learners. However, most often than not, it becomes a function of where you live, the languages you speak and how much can you pay. At LearnHat, we intent to change this. Whether a student/learner lives in a remote location or speaks only regional languages or cannot afford a hefty fee, they deserve top notch education.",
        },
      },
    },
    footer: {
      links: [
        {
          text: "Terms & Conditions",
          path: "/terms",
        },
        {
          text: "Refund Policy",
          path: "/",
        },
        {
          text: "Privacy Policy",
          path: "/privacy",
        },
        {
          text: "Sitemap",
          path: "/",
        },
      ],
      copyright:
        "© Orion Softech & Contentgrill Pvt. Ltd 2021 | All Rights Reserved",
    },
    courses_k12: {
      title: "Course For Class 1 – 12",
      features: [
        "Upto 20 LIVE Interactive Classes/month",
        "Batch size of up to 8 learners only",
        "Monthly Improvement Report for Parents",
        "School Exam Preparation and Revision Notes",
        "Focused on conceptual understanding of topics",
      ],
      subjects: {
        title: "Subjects we teach",
        "1-5": {
          subjects: [
            {
              name: "All Subjects",
              icon: "allsubjects",
              starting: "Starting ₹2083/month",
            },
            {
              name: "Math",
              icon: "math",
              starting: "Starting ₹833/month",
            },
            {
              name: "English",
              icon: "eng",
              starting: "Starting ₹833/month",
            },
          ],
          message:
            "Our expert tutors provide conceptual understanding and in-depth knowledge for our young learners.",
        },
        "6-8": {
          subjects: [
            {
              name: "All Subjects",
              icon: "allsubjects",
              starting: "Starting ₹2250/month",
            },
            {
              name: "Math",
              icon: "math",
              starting: "Starting ₹917/month",
            },
            {
              name: "English",
              icon: "eng",
              starting: "Starting ₹917/month",
            },
            {
              name: "Science",
              icon: "pcm",
              starting: "Starting ₹917/month",
            },
          ],
          message:
            "Tailor-made online courses focused on building strong aptitude skills for your child.",
        },
        "9-10": {
          subjects: [
            {
              name: "All Subjects",
              icon: "allsubjects",
              starting: "Starting ₹2542/month",
            },
            {
              name: "Math",
              icon: "math",
              starting: "Starting ₹1000/month",
            },
            {
              name: "English",
              icon: "eng",
              starting: "Starting ₹1000/month",
            },
            {
              name: "Science",
              icon: "pcmb",
              starting: "Starting ₹1000/month",
            },
          ],
          message:
            "Our LIVE classes are designed to enhance higher-order thinking and focus on holistic growth.",
        },
        "11-12": {
          subjects: [
            {
              name: "All Subjects",
              icon: "allsubjects",
              starting: "Starting ₹2958/month",
            },
            {
              name: "Math",
              icon: "math",
              starting: "Starting ₹1208/month",
            },
            {
              name: "English",
              icon: "eng",
              starting: "Starting ₹1208/month",
            },
            {
              name: "Science",
              icon: "pcb",
              starting: "Starting ₹2958/month",
            },
          ],
          message:
            "Comprehensive learning programs centered on skill attainment and core competencies.",
        },
      },
      boards: {
        title: "Boards we cover",
        list: ["CBSE", "ICSE", "WBBCE", "and More"],
      },
      languages: {
        title: "Languages we teach in",
        list: ["English", "हिंदी", "বাংলা", "मराठी", "also your home language"],
      },
      cta: "Take Free Trial Class",
    },
    courses_adult_learner: {
      title: "Course For Adult Learners",
      features: [
        "Upto 20 LIVE Interactive Classes/month",
        "Batch size of up to 8 learners only",
        "Focused on conceptual understanding of topics",
      ],
      subjects: {
        title: "Subjects we teach",
        subjects: [
          {
            name: {
              path: "/spoken-english",
              text: "Spoken English",
            },
            icon: "spokeneng",
            course_starting: "Courses starting at ₹ 1167/Month",
          },
          {
            name: "Yoga",
            icon: "yoga",
            course_starting: "Courses starting at ₹ 600/Month",
          },
          {
            name: "Abacus",
            icon: "abacus",
            course_starting: "Courses starting at ₹ 600/Month",
          },
        ],
      },
      cta: "Take Free Trial Class",
    },
    spoken_english: {
      hero_main_text: {
        red: "LIVE",
        start: "English Communication & Public Speaking Course",
      },
      hero_description: "Speak like a star!",
      learn_better: {
        title: "Learn Better, Grow Faster",
        form_heading: "Book Your Free Spoken English Class Today!",
        cards: [
          {
            icon: "vocabulary1",
            heading: "Improve your grammar & vocabulary.",
            subheading:
              "Be a star in every discussion. Inspire those around you.",
          },
          {
            icon: "public-speaking1",
            heading: "Enhance public speaking skills",
            subheading: "Get better at comprehension and participate actively.",
          },
          {
            icon: "personality1",
            heading: "Develop your personality",
            subheading:
              "Effective communication is the ticket to lifelong success.",
          },
        ],
        cta: "Take Free Spoken English Trial Class",
      },
      progress_ladder: {
        title: {
          heading: "Your Progress Ladder",
          subheading: "With our interactive classes",
        },
        left_cards: [
          {
            icon: "progress1",
            heading: "Begin With Your Current Level",
            subheading: "Kickstart your learning journey with LearnHat",
          },
          {
            icon: "progress2",
            heading: "Progress fast, level up",
            subheading: "Identify key focus areas and improve your skills",
          },
          {
            icon: "progress3",
            heading: "Empower Yourself & Be A Star",
            subheading: "Empower yourself with the best-in-class education",
          },
        ],
        right_points: {
          title: "Speak Fluently with the Learnhat Process",
          points: [
            "Build over 3000 New Vocab Words",
            "Master Fluency of English",
            "Build an Easy Going Personality",
            "Strong Communication Skills",
            "Enhance your grammar",
            "Master Art of Public Speaking",
            "Improve on elocution and pronunciation",
          ],
        },
      },
      features: {
        focus: {
          heading: "Our Specialized",
          subheading: "Focus Areas for Holistic Growth",
          cards: [
            {
              icon: "focus1",
              text: "Personality Development",
            },
            {
              icon: "focus2",
              text: "Pronunciation and Articulation",
            },
            {
              icon: "focus3",
              text: "Grammar and Vocabulary",
            },
            {
              icon: "focus4",
              text: "Public Speaking",
            },
          ],
        },
        highlight: {
          heading: "The LearnHat",
          subheading: "Way of Learning",
          cards: [
            {
              icon: "highlight1",
              text: "LIVE Interactive Online Classes",
            },
            {
              icon: "highlight2",
              text: "Regular discussions and speaking activities",
            },
            {
              icon: "highlight3",
              text: "Personalized Learning in Small Batches",
            },
          ],
        },
      },
      pricing: {
        heading: "Like What You See? Grab A Pack Now!",
        subheading: "Get highly interactive, super fun, immersive classes",
        keypoints: [
          "Beginner to Advance level courses",
          "LIVE classes to enhance speaking skills",
          "Growth evaluation via in-built assessments",
        ],
        plans: [
          {
            plan: "Monthly",
            base_price: "1500",
            total_class: {
              text: "Total Class",
              number: "08",
            },
            per_class: {
              text: "Per Class",
              price: "188",
            },
            desc: {
              line1: "Be a star in every discussion",
              line2: "Inspire those around you.",
            },
          },
          {
            plan: "Quarterly",
            base_price: "3800",
            total_class: {
              text: "Total Class",
              number: "24",
            },
            per_month: {
              text: "Per Month",
              price: "1267",
            },
            per_class: {
              text: "Per Class",
              price: "158",
            },
            desc: {
              line1: "Be a star in every discussion",
              line2: "Inspire those around you.",
            },
          },
          {
            plan: "Half-Yearly",
            base_price: "7000",
            total_class: {
              text: "Total Class",
              number: "48",
            },
            per_month: {
              text: "Per Month",
              price: "1167",
            },
            per_class: {
              text: "Per Class",
              price: "148",
            },
            desc: {
              line1: "Be a star in every discussion",
              line2: "Inspire those around you.",
            },
          },
        ],
        cta: "Take Free Spoken English Trial Class",
      },
    },
    teach_with_us: {
      hero_main_text: {
        start: "Teach",
        red: "LIVE",
        end: "at your convenience from the comfort of your HOME",
      },
      hero_description: "Join our community of tutors!",
      why_learnhat: {
        heading: "Why LearnHat",
        cards: [
          {
            icon: "teachwithus-whylh-icon1",
            text: "Teach at your convenience (day & time)",
          },
          {
            icon: "teachwithus-whylh-icon2",
            text: "Teach in English as well as other languages you speak",
          },
          {
            icon: "teachwithus-whylh-icon3",
            text: "Direct earnings for every class that you take",
          },
          {
            icon: "teachwithus-whylh-icon4",
            text: "Earn for demo classes as well as content created",
          },
          {
            icon: "teachwithus-whylh-icon5",
            text: "Connect with other top tutors and be a part of the community",
          },
          {
            icon: "teachwithus-whylh-icon6",
            text: "Incentives and other revenue opportunities too",
          },
        ],
      },
      steps: {
        heading: "Be Part Of Our Stellar Faculty",
        cards: [
          {
            icon1: "one",
            icon2: "teachwithus-step1",
            text: "Fill up our hiring form and attach your short demo teaching video.",
          },
          {
            icon1: "two",
            icon2: "teachwithus-step2",
            text: "Get shortlisted for the 1:1 personal interview.",
          },
          {
            icon1: "three",
            icon2: "teachwithus-step1",
            text: "Get onboarded and you're ready to begin teaching with LearnHat!",
          },
        ],
      },
      testimonials: {
        heading: "Life At LearnHat",
        subheading: "Teacher Testimonials",
        testimonialsData: [
          {
            text: "Teaching does get easier with more experience and technology, but teachers of all levels need a little extra inspiration and exposure, which we get here in LearnHat!",
            name: "Sania Mirza Khan",
            imgUrl:
              "https://res.cloudinary.com/learnhat/image/upload/v1629224784/website_images/teachers/Sania_Khan.jpg",
            designation: "Math Teacher",
          },
          {
            text: "LearnHat is a unique digital platform for millions of learners. It is offering a massive opportunity to the teachers/ tutors not only to earn at ease but also to shape the future of those millions with their brilliant guidance and interactive updated application based sessions.",
            name: "Ritashree Mazumdar",
            imgUrl:
              "https://res.cloudinary.com/learnhat/image/upload/v1633005543/website_images/teachers/Ritashree_eqac9z.jpg",
            designation: "English Teacher",
          },
          {
            text: "Learnhat  is the best e-learning platform for students as well as teachers and it is quite affordable as compared to other platforms . Flexible timings, interactive sessions, language variations all are there. So, students can enhance their knowledge. It's a great honour to be a part of it.",
            name: "Nabanita Nag",
            imgUrl:
              "https://res.cloudinary.com/learnhat/image/upload/v1633004972/website_images/teachers/Nabanita_Nag.jpg",
            designation: "All Subjects(1-5)",
          },
        ],
      },
      faq: {
        heading: "Frequently Asked Questions",
        questions: [
          {
            question: "How long is the hiring process at LearnHat?",
            answer:
              "The hiring process is extremely simple and quick. You just need to fill our online form and attach your teaching demo for our experts to review. On an average the entire process can be completed within 2 - 3 weeks.",
          },
          {
            question: "How much does an average teacher earn on LearnHat?",
            answer:
              "Teachers can earn as much as Rs 40,000 per month on LearnHat. Our per class model allows flexibility and helps teachers to maximize their earning potential.",
          },
          {
            question:
              "What are the basic requisites to conduct LIVE online tuition at LearnHat?",
            answer: {
              ul_head: "Hardware requisites:",
              points: [
                "A laptop/desktop with good processing power",
                "Minimum of 4 GB RAM",
                "Broadband Internet connectivity (at least 4 mbps) is required}",
              ],
              desc: "Computer proficiency is a must as well for all tutors.Our Academic Team also looks for expertise in the field of teaching during the profile screening process.",
            },
          },
          {
            question:
              "Will I be given any training to conduct these LIVE online sessions?",
            answer:
              "You will be provided with onboarding training on how to use and teach on the LearnHat platform. Our team is also available to help clear your doubts and troubleshoot at any point in time",
          },
          {
            question: "Do I need to sign any contracts or bonds?",
            answer:
              "At LearnHat, you can teach at your own flexibility, and hence you do not have to sign any contract or bond with us.",
          },
          {
            question: "What subjects are offered at LearnHat?",
            answer:
              "LearnHat offers K12 learning for all classes, all boards in multiple languages of instruction (vernaculars). Apart from that, we also offer miscellaneous courses for adult as well as young learners like Yoga, Accounting, Spoken English, Foreign Languages, Web & Mobile Development, and more.",
          },
        ],
      },
    },
    terms_and_conditions: {
      heading: "Terms & Conditions",
      subheading: "User's Terms Of Use",
      introduction:
        "This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of use and practices for access and usage of any functional website. This website is created and operated by M/s Orion Softech & Contentgrill Private Limited, (hereinafter referred to as “We”, “Our”, and “Us”) a private limited company incorporated and registered under the provisions of the Companies Act, 2013 and operating under the brand name “Learnhat.com”. We intend to ensure your steady commitment to the usage of this website and the services provided by us through our website “www.Learnhat.com” For the purpose of these Terms of use (“Terms of use”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the website. “You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals who shall be users of this website and the services provided by us or the service providers and who are competent to enter into binding contracts, as per Indian laws. “Third Parties” refer to any website, platform or individual apart from the users and the creator of this website.",
      terms: [
        {
          title: "General Terms",
          points: [
            "The headings of each section in these Terms of use are only for the purpose of organizing the various provisions under these Terms of use in an orderly manner and shall be used by you to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall have legal or contractual value on your usage of the website.",
            "The use of this website is solely governed by these Terms of use, Privacy Policy that may be uploaded on the website and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a user continue to access and use this website, you are agreeing to comply with and be bound by the following Terms of use and Privacy Policy.",
            "You expressly agree and acknowledge that these Terms of use and Privacy Policy are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.",
            "You unequivocally agree that these Terms of use and the Privacy Policy constitute a legally binding agreement between us, and that you shall be subject to the rules, guidelines, policies, terms, and conditions applicable to your use of the website, and that the same shall be deemed to be incorporated into these Terms of use and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or express act is required to make these Terms of use and the Privacy Policy binding on you and that your act of visiting any part of the website constitutes your full and final acceptance of these Terms of use and the Privacy Policy.",
            "We reserve the sole and exclusive right to amend or modify these Terms of use without any prior permission or intimation to you, and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, you must stop using the website at once. Your continued use of the website will signify your acceptance of the changed terms.",
          ],
        },
        {
          title: "Eligibility",
          points: [
            "You represent and warrant that you are competent and eligible to enter into legally binding agreements and that you have the requisite authority to bind yourself to these Terms of use or represent a minor as a legally authorised parent or guardian following the law. All acts of minors shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian.",
            "You further represent that you will comply with these Terms of use and all applicable local, state, national and international laws, rules and regulations.",
            "You shall not use the website if you are not competent to contract or are disqualified from doing so by any other applicable law, rule, or regulation currently in force.",
          ],
        },
        {
          title: "Payment Gateway",
          description: [
            "For making all payments for purchases on the website, you shall be required to make a payment for which you will be redirected to a third-party payment gateway that we may have an agreement with. The payment gateway may additionally seek information from you to process the payment for your payment and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.",
          ],
        },
        {
          title: "Content",
          description: [
            "All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music, and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and we have no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the website. All the Content displayed on the website is subject to copyright and shall not be reused by you (or a third party) without the prior written consent from us and the copyright owner. You are solely responsible for the integrity, authenticity, quality, and genuineness of the content provided on the website and whilst feedback and comments by you can be made via the website, we bear no liability whatsoever for any feedback or comments made by the other users or made in respect of any of the content on the website. Further, the website reserves its right to suspend the account of any user for an indefinite period to be decided at the discretion of the website or to terminate the account of any user who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the website. You shall not copy, adapt, and modify any content without written permission from us.",
          ],
        },
        {
          title: "Indemnity",
          description: [
            `You agree to indemnify, defend and hold harmless us, and our respective directors, officers, employees and agents (collectively, "Parties"), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of use. Further, you agree to hold us harmless against any claims made by any third party due to, or arising out of, or in connection with:`,
            {
              text: "",
              subpoints: [
                "Your use of the website.",
                "Your violation of these Terms of use.",
                "Your violation of any rights of another.",
                "Your alleged improper conduct according to these Terms of use.",
                "Your conduct in connection with the website.",
              ],
            },
            `You agree to fully cooperate in indemnifying us at your expense. You also agree not to settle with any party without consent from us. In no event shall we be liable to compensate you or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not you had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with your use of or access to the website and/or the services or materials contained therein.`,
          ],
        },
        {
          title: "Limitation Of Liability",
          points: [
            {
              text: "We are not responsible for any consequences arising out of the following events:",
              subpoints: [
                "If the website is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure.",
                "If you have fed incorrect information or data or for any deletion of data.",
                "If there is an undue delay or inability to communicate through email.",
                "If there is any deficiency or defect in the services managed by us.",
                "If there is a failure in the functioning of any other service provided by us.",
              ],
            },
            "The website accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to you, your belongings, or any third party, resulting from the use or misuse of the website or any service availed of by us through the website. The service and any Content or material displayed on the service is provided without any guarantees, conditions, or warranties as to its accuracy, suitability, completeness, or reliability. We will not be liable to you for the unavailability or failure of the website.",
            "You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of use by reference.",
            "The website expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the website and which is incurred by you in connection with the website, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of use.",
            "To the fullest extent permitted by law, we shall not be liable to you or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the website.",
          ],
        },
        {
          title: "Term",
          points: [
            "These Terms of use shall continue to form a valid and binding contract between us and shall continue to be in full force and effect until you continue to access and use the Websites.",
            "You may terminate your use of the website at any time.",
            "We may terminate these Terms of use and close your account at any time without notice and/or suspend or terminate your access to the website at any time and for any reason, if any discrepancy or legal issue arises.",
            "Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.",
            "It is also hereby declared that we may discontinue the services and Websites without any prior notice.",
          ],
        },
        {
          title: "Termination",
          points: [
            "We reserve the right, in its sole discretion, to unilaterally terminate your access to the website, or any portion thereof, at any time, without notice or cause.",
            "We also reserve the universal right to deny access to you, to any/all of are on its website without any prior notice/explanation to protect the interests of the website and/or other users to the website.",
            "We reserve the right to limit, deny or create different access to the website and its features concerning different users, or to change any of the features or introduce new features without prior notice.",
            "You shall continue to be bound by these Terms of use, and it is expressly agreed to by you that you shall not have the right to terminate these Terms of use till the expiry of the same.",
          ],
        },
        {
          title: "Communication",
          description: [
            "By using this website and providing your identity and contact information to us through the website, you agree and consent to receive calls, e-mails, or SMS from us and/or any of its representatives at any time.",
            "You can report to info@Learnhat.com if you find any discrepancy about website or content-related information and we will take necessary action after an investigation. The response with resolution (if any issues found) shall be dependent on the time taken for investigation",
            "You expressly agree that notwithstanding anything contained herein above, it may be contacted by us relating to any services availed by you on the website or anything pursuant thereto and you agree to indemnify us from any harassment claims. It is expressly agreed to by us that any information shared by us shall be governed by the Privacy Policy.",
          ],
        },
        {
          title: "User Obligations And Formal Undertakings As to Conduct",
          description: [
            {
              text: "You agree and acknowledges that you are a restricted user of this website and you:",
              subpoints: [
                "Agree to provide genuine credentials whenever required on the website. You shall not use a fictitious identity. We are not liable if you have provided incorrect information.",
                "Agree to ensure the Name, Email address, Mobile number and any such other information that may be provided and shall keep your information accurate and up to date.",
                "Understand and acknowledge that the data submitted is manually entered into the database of the website. You also acknowledge the fact that data so entered into the database is for easy and ready reference for you, and to streamline the services through the website.",
                "Understand and agree that, to the fullest extent permissible by law, the website or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the website or this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.",
                "Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the website. Any such use/limited use of the website will only be allowed with the prior express written permission.",
                "Agree not to access (or attempt to access) the website and/or the materials or services by any means other than through the interface provided by the website. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the website or its content, or in any way reproduce or circumvent the navigational structure or presentation of the website, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the website will lead to suspension or termination of your access to the website. We disclaim any liabilities arising concerning such offensive content on the website.",
                "Expressly agree and acknowledge that the Content generated by the users and displayed on the website is not owned by us and that we are in no way responsible for the content of the same. You may, however, report any offensive or objectionable content, which we may then remove from the website, at our sole discretion.",
              ],
            },
            {
              text: "You further undertake not to:",
              subpoints: [
                "Engage in any activity that interferes with or disrupts access to the website or the services provided therein (or the servers and networks which are connected to the website).",
                "Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity.",
                "Probe, scan or test the vulnerability of the website or any network connected to the website, nor breach the security or authentication measures on the website or any network connected to the website. The user may not reverse look-up, trace or seek to trace any information relating to any other user of, or visitor to, the website, or any other viewer of the website, including any user account maintained on the website not operated/managed by the user, or exploit the website or information made available or offered by or through the website, in any manner.",
                "Disrupt or interfere with the security of, or otherwise cause harm to, the website, systems resources, accounts, passwords, servers, or networks connected to or accessible through the website or any affiliated or linked Websites.",
                "Use the website or any material or content therein for any purpose that is unlawful or prohibited by these Terms of use, or to solicit the performance of any illegal activity or other activity which infringes the rights of this website or any other third party (s).",
                "Violate any code of conduct or guideline which may apply for or to any particular service offered on the website.",
                "Violate any applicable laws, rules, or regulations currently in force within or outside India.",
                "Violate any portion of these Terms of use or the Privacy Policy, including but not limited to any applicable additional terms of the website contained herein or elsewhere, whether made by amendment, modification or otherwise.",
                `Commit any act that causes us to lose (in whole or in part) the services of its Internet Establishment ("ISP") or in any manner disrupts the services of any other supplier/service provider of the website.`,
                "You hereby expressly authorize us to disclose all information relating to you in our possession to law enforcement or other government officials, as we may in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the website might be directed to disclose any information (including the identity of persons providing information or materials on the website) as necessary to satisfy any judicial order, law, regulation or valid governmental request.",
                "By indicating your acceptance to use any services offered on the website, you are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete.",
                "You agree to use the services provided by us, our affiliates, consultants and contracted companies, for lawful purposes only.",
                "You agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details provided by you at any point in time. If upon confirmation such details are found to be false, not to be true (wholly or partly), we shall in our sole discretion reject the registration and debar you from using the website without prior intimation whatsoever.",
                {
                  text: "You agree not to post any material on the website that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful or advertising any goods or services. More specifically, you agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:",
                  subpoints: [
                    "belongs to another person and to which you have no right to",
                    "is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever",
                    "is in any way harmful to minors",
                    "infringes any patent, trademark, copyright or other proprietary rights",
                    "violates any law for the time being in force",
                    "deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing",
                    "Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others",
                    "Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity",
                    `Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986`,
                    "Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation",
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "Suspension of User Access and Activity",
          description: [
            {
              text: `"Notwithstanding other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the website, and/or refuse to the usage of the website, without being required to provide you with notice or cause:"`,
              subpoints: [
                "If you are in breach of any of these Terms of use or the Privacy Policy.",
                "If you have provided wrong, inaccurate, incomplete, or incorrect information.",
                "If your actions may cause any harm, damage or loss to the other users or us, at our sole discretion.",
              ],
            },
          ],
        },
        {
          title: "Intellectual Property Rights",
          description: [
            "Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of use that shall be available on the website. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the website or such other third party and other distinctive brand features of the website are the property of the website or the respective copyright or trademark owner. Furthermore, concerning the website, we shall be the exclusive owner of all the designs, graphics and the like, related to the website.",
            "You shall not use any of the intellectual property displayed on the website in any manner that is likely to cause confusion among existing or prospective users of the website, or that in any manner disparages or discredits the website, to be determined in the sole discretion.",
            "You are aware of all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the creators. You are aware that we merely provide the website through which you can communicate with other users and the website does not own any of the intellectual property relating to the independent content displayed on the website, apart from created graphics and specified content.",
            "You are further aware that any reproduction or infringement of the intellectual property of the owners by you will result in legal action being initiated against you by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by you that the contents of this section shall survive even after the termination or expiry of these Terms of use and/or Privacy Policy.",
          ],
        },
        {
          title: "Disclaimer of Warranties and Liabilities",
          points: [
            "You further agree and undertake that you are accessing the website at your sole risk and are using the best and prudent judgment before availing of any features on the website or accessing/using any information displayed thereon.",
            "You agree that any kind of information, resources, activities, recommendations obtained/availed from the website, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.",
            "We do not guarantee that the features and content contained on the website will be uninterrupted or error-free, or that the website or its server will be free of viruses or other harmful components, and you hereby expressly accept any associated risks involved with your use of the website. It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of use and/or Privacy Policy.",
            "It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of use and/or Privacy Policy.",
          ],
        },
        {
          title: "Force Majeure",
          description: [
            "We will not be liable for damages for any delay or failure to perform our obligations hereunder if such delay or failure is due to cause beyond our control or without its fault or negligence, due to force majeure events including but not limited to acts of war, acts of god, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.",
          ],
        },
        {
          title: "Dispute Resolution and Jurisdiction",
          description: [
            "It is expressly agreed to by you hereto that the formation, interpretation, and performance of these Terms of use and any disputes arising therefrom will be resolved through Arbitration. It is further agreed to by you that the contents of this section shall survive even after the termination or expiry of the Terms of use and/or Privacy Policy.",
            "Arbitration: In the event that the Parties are unable to amicably resolve a dispute, the said dispute will be referred to arbitration by a sole arbitrator to be appointed by us, and the award passed by such sole arbitrator will be valid and binding on all parties. All costs of the Arbitration shall be borne solely in accordance with the Arbitrators award. The arbitration shall be conducted in English, and the seat of Arbitration shall be the city of Bengaluru, Karnataka, India.",
            "You expressly agree that the Terms of use, Privacy Policy and any other agreements entered between the Parties are governed by the laws, rules, and regulations of India.",
          ],
        },
        {
          title: "Miscellaneous Provisions",
          points: [
            "Entire Agreement: These Terms of use, read with the Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.",
            "Waiver: The failure at any time to require performance of any provision of these Terms of use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of use, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of use.",
            "Severability: If any provision/clause of these Terms of use is held to be invalid, illegal, or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of use shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms of use shall be reformed to the minimum extent necessary to correct any invalidity, illegality, or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.",
            "Contact Us: If you have any questions about these Terms of use, the practices of the website, or your experience, you can contact us by emailing at info@Learnhat.com",
            "The person filling out the form with the given mobile number agrees that LearnHat may use the submitted data for its marketing purposes through call, email, SMS, whatsapp and other relevant media.",
          ],
        },
      ],
    },
    privacy: {
      title: "Privacy",
      introduction: [
        "Learnhat.com values your trust. In order to honour that trust, Learnhat.com adheres to ethical standards in gathering, using, and safeguarding any information you provide.",
        "Orion softech & Contentgrill Pvt Ltd. (operating under the brand name Learnhat.com), is a leading edtech company, incorporated in India, for improving learning outcomes for students. For the sake of brevity of understanding this Privacy Policy the company will be hereinafter referred as Learnhat.com.",
        "This privacy policy governs your use of the www.Learnhat.com(“Website”) and the other associated/ancillary applications, products, websites and services managed by the Company, ",
        "Please read this privacy policy (“Policy“) carefully before using the Website, its services and products, along with the Terms of Use (“ToU“) provided on the Website. Your use of the Website, or services in connection with the Website or products (“Services“), or registrations with us through any mode or use of any products including that of SD cards, tablets or other storage/transmitting device shall signify your acceptance of this Policy and your agreement to be legally bound by the same. For the sake of brevity your use of ‘Learnhat.com’ in any electronic form or device shall be bound by the terms and conditions enumerated and agreed upon hereunder with wilful and free consent.",
        "If you wish not to agree with any of the terms and conditions of this Policy, kindly refrain from using the Website, Application or its products or until satisfaction for you to use the same by its Sales/Marketing/Technical/Legal Team, avail any of these services.",
      ],
      points: [
        {
          title: "User Provided Information",
          data: [
            {
              text: "The Website/Services/products/Mobile App records the information you provide when you register for the Website or Services or products or App. When you register with us, you generally provide",
              subpoints: [
                "your name, age, email address, phone number, password and your ward’s educational interests;",
                "transaction-related information, such as when you make purchases, respond to any offers, or download or use applications from us;",
                "information you provide us when you contact us for help;",
                "information you enter into its system when using the Website/Services/products, such as while asking doubts, participating in discussions and taking tests.",
              ],
            },
            `The said information collected from the users could be categorized as “Personal Information“, “Sensitive Personal Information” and “Associated Information”. Personal Information, Sensitive Personal Information and Associated Information (each as individually defined under this Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 (the “Data Protection Rules”)) shall collectively be referred to as “Information” in this Policy.`,
            "Learnhat.com may use this Information to contact you from time to time, to provide you with the Services, important information, required notices and marketing promotions. Learnhat.com will request for more information that personally identifies you (personal information) or allows us to contact you.",
            "Learnhat.com will not differentiate between who is using the device to access the Website or Services or products, so long as the log in/access credentials match with yours. In order to make the best use of the App/Website/Services/products and enable your Information to be captured accurately on the App/Website/Services/products, it is essential that you have logged in using your own credentials.",
            "Learnhat.com will, at all times, provide the option to you, not to provide the Personal Information or Sensitive Personal Information, which it seeks from you. Further, you can, at any time while using theServices/products, also have an option to withdraw your consent given earlier to it, to use such Personal Information or Sensitive Personal Information. Withdrawal of the consent by you is required to be sent in writing to us at the contact details provided in this Policy below. In any such event, Learnhat.com or the Company fully reserves the right to withdraw further usage of theWebsite or provide any further Services/products thereunder to you.",
          ],
        },
        {
          title: "Automatically Collected Information",
          data: [
            "In addition, the products/Services may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use theServices/products. As is true for most Mobile applications, Learnhat.com also collects other relevant information as per the permissions that you provide.",
            "Learnhat.com may use an outside financial processing company to bill you for the goods and services availed by you. These companies do not retain, share, store or use your personally identifiable information for any other purpose.",
          ],
        },
        {
          title: "Use of your Personal Information",
          data: [
            "Learnhat.com uses the collected Information to analyse trends, to conduct research, to administer the Services and products, to learn about each user’s learning patterns and movements around theServices and products and to gather demographic information and usage behaviour about its user base as a whole. Aggregated and individual, anonymized and non-anonymized data may periodically be transmitted to external service providers to help us improve the Website, products and its Services. Learnhat.com will share your information with third parties only in the ways that are described below in this Policy.",
            "Learnhat.com may use the individual data and behavior patterns combined with personal information to provide you with personalized content, and better your learning objectives/experience. Third parties provide certain services which we may use to analyze the data and information you provide to personalize, drive insights and help improve your experience or reach out to you with more value added applications, products, information and services. However, these third party companies do not have any independent right to share this information. Learnhat.com does not sell, trade or rent your Information to any third party unless, we have been expressly authorized by you either in writing or electronically to do so. Learnhat.com may at times provide aggregate statistics about its customers, traffic patterns, and related site information to reputable third parties, however this information when disclosed will be in an aggregate form and does not contain any of your Personally Identifiable Information.",
            "Learnhat.com will occasionally send email notices or contact you to communicate about its Services, products and benefits, as they are considered an essential part of the Website/Services/products you have chosen.",
          ],
        },
        {
          title: "Learnhat.com may disclose Information:",
          data: [
            {
              text: "",
              subpoints: [
                "As required by law, such as to comply with a subpoena, or similar legal process;",
                "To enforce applicable ToU, including investigation of potential violations thereof;",
                "When it believes in good faith (doctrine of uberrima fides) that the disclosure is necessary to protect its rights, protect your safety or the safety of others, investigate fraud, address security or technical issues or respond to a government request;",
                "With its trusted services providers who work on its behalf, do not have an independent use of the information Learnhat.com discloses to them, and have agreed to and adhered to the rules set forth in this Policy;",
                "To protect against imminent harm to the rights, property or safety of the Website/ Company or its users or the public as required or permitted by law;",
                "With third party service providers in order to personalize the App/Website/Services/products for a better user experience and to perform behavioural analysis;",
                "Any portion of the Information containing personal data relating to minors provided by you shall be deemed to be given with the consent of the minor’s legal guardian. Such consent is deemed to be provided by your registration with Learnhat.com.",
              ],
            },
          ],
        },
        {
          title: "Access to your Personal Information",
          data: [
            "Learnhat.com will provide you with the means to ensure that your Personal Information is correct and current. If you have filled out a user profile, it will provide an obvious way for you to access and change your profile from itsServices/Website/products. Learnhat.com adopts stringent security measures to protect your password from being exposed or disclosed to anyone. Only upon you forgetting the password for its Website or Services, Learnhat.com will have a right to reset the same to you with your prior approval as per standard web practices.",
          ],
        },
        {
          title: "Cookies",
          data: [
            "Learnhat.com sends cookies (small files containing a string of characters) to your computer, thereby uniquely identifying your browser. Cookies are used to track your preferences, help you login faster, and aggregated to determine user trends. This data is used to improve its offerings, such as providing more content in areas of greater interest to a majority of users.",
            "Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent.",
            "Disclaimer:",
            "Some of Learnhat.com features and services may not function properly if your cookies are disabled.",
          ],
        },
        {
          title: "Links",
          data: [
            "Learnhat.com may present links in a format that enables us to keep track of whether these links have been followed. Learnhat.com uses this information to improve its customized content. Clicking on links may take you to sites outside its domain. Learnhat.com are not responsible for the privacy practices of other web sites. Learnhat.com encourages its users to be aware when they leave its site to read the EULA/T&Cs of each and every web site that collects personally identifiable information. This Privacy Policy applies solely to information collected by its Website.",
          ],
        },
        {
          title: "Alerts",
          data: [
            "Learnhat.com may alert you by email or phone (through whatsapp/ sms/call) to inform you about new service offerings or other information which it perceives might be useful for you",
          ],
        },
        {
          title: "Public Forums",
          data: [
            "When you use certain features on its website like the discussion forums and you post or share your personal information such as comments, messages, files, photos, will be available to all users, and will be in the public domain. All such sharing of information is done at your own risk. Please keep in mind that if you disclose personal information in your profile or when posting on its forums this information may become publicly available.",
          ],
        },
        {
          title: "Security",
          data: [
            "Learnhat.com is concerned about safeguarding the confidentiality of your Information. Learnhat.com provides physical, electronic, and procedural safeguards to protect Information it processes and maintains. An illustration being, Learnhat.com limits access to this Information to authorized employees only who need to know that information in order to operate, develop or improve its App/Services/products/Website. Please be aware that, although Learnhat.com endeavour is to provide stringent security, for information it possesses and maintains, no security system can prevent all potential security breaches.",
          ],
        },
        {
          title: "How long does Learnhat.com retain user data?",
          data: [
            "Currently, Learnhat.com retains user data while an account is active and for at least five years afterwards. It may alter this practice according to legal and business requirements. For example, it may lengthen the retention period for some data if needed to comply with law or voluntary codes of conduct. Unless otherwise prohibited, it may shorten the retention period for some types of data if needed to free up storage space.",
          ],
        },
        {
          title: "Log information",
          data: [
            "When you access Learnhat.com Website, its servers automatically record information that your browser sends whenever you visit a website. These server logs may include information such as your web request, internet protocol address, browser type, browser language, the date and time of your request and one or more cookies that may uniquely identify your browser.",
          ],
        },
        {
          title: "Child Information",
          data: [
            "Subject to your consent, in order to allow your child to use Learnhat.com Learning's Site and Services, we would like to collect, use and disclose your child’s Personal Data as set forth below",
            {
              text: "You, as the parent/guardian, are the primary account holder. This means that:",
              subpoints: [
                "You are the person primarily responsible for ordering services and paying any fees or other charges made to Learnhat.com Learning;",
                "You are responsible for deciding whether to share your child’s content with third parties;",
                "You are responsible for the active monitoring of your child’s content. Children who are under the age of 13 are expressly prohibited from including any personally identifiable information in any content that they post to our website unless or until their parents or legal guardians give permission. You agree that you will only allow your child to include any personally identifiable information in their content with your consent.",
              ],
            },
            {
              text: "Personal Data We Collect.",
              subpoints: [
                "When we receive consent from you, we may collect additional personal data about your child or you from you, at that time;",
                "Your child may choose to provide Personal Data about themselves in the content they post on our website. However, as outlined above, Learnhat.com Learning requires that you first consent to our collection and use of this Personal Data;",
                "Personal Data collected if your child contacts us: If your child contacts us by email, through any integrated chat providers we may provide, or by telephone, SMS, or physical mail, we may collect your child’s name, username, email address, phone number, physical address, and the contents of that communication which can include any other Personal Data that your child chooses to provide. We will only use that Personal Data for the purpose of providing the service or support requested;",
                "Log Data: Learnhat.com Learning’s website collects certain personal data automatically and stores it in log files. This may include personal data about the devices your child uses to access our Service (including your child’s internet protocol (IP) address,",
              ],
            },
          ],
        },
      ],
    },
  },
};
